import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { ListItemText, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setPage } from '../../appNavigationSlice';
import { selectTeams, setSelectedTeamId } from '../user/teamsSlice';

export function TeamsBar() {
  const dispatch = useAppDispatch();
  const teamsState = useAppSelector(selectTeams);

  console.log(teamsState);

  function handleSelectChange(e: any) {
    dispatch(setSelectedTeamId(e.target.value));
    dispatch(setPage('home'));
  }
  return (
    <Box sx={{ display: 'flex', mx: 3, alignItems: 'center' }}>
      <Select
        sx={{ minWidth: '200px', mx: 1, alignItems: 'center' }}
        size="small"
        label="Team"
        value={teamsState.selectedTeamId ?? -1}
        onChange={(e) => handleSelectChange(e)}
      >
        {teamsState.teamMemberships?.map((membership, index) => (
          <MenuItem value={membership.team.id} key={membership.team.id}>
            <ListItemText>
              <b>{membership.team.name}</b> ({membership.role})
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
