import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Input from "@mui/material/Input";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { grey } from "@mui/material/colors";

import logo from "../../common/logo.png";
import { SliderQuestion } from "../../../types/Question";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import {
  addOption,
  addQuestion,
  addSelectedAnswerId,
  clearSelectedAnswerId,
  deleteOption,
  selectAnswer,
  selectorQuestions,
  setSelectedQuestionId,
} from "../../surveydesigner/SurveyDesignerSlice";

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function SliderPreview(props: {
  question: SliderQuestion;
}) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box sx={{ p: 1 }}>
      { !props.question.has_multiple && <Box>
        <Slider
          aria-label={props.question.title}
          defaultValue={props.question.default_value}
          valueLabelDisplay="auto"
          step={props.question.step}
          marks
          min={props.question.minimum}
          max={props.question.maximum}
        />
        </Box> }
        { props.question.has_multiple && <Box>
          { props.question.options.map(_ => <Box key={ props.question.id + "_slider_box" + _.id }><InputLabel key={ props.question.id + "_slider_il" + _.id }>{ _.name }</InputLabel>
          <Slider
              key={ props.question.id + "_slider" + _.id }
              aria-label={_.name}
              defaultValue={props.question.default_value}
              valueLabelDisplay="auto"
              step={props.question.step}
              marks
              min={props.question.minimum}
              max={props.question.maximum}
            />
            </Box> ) }
          </Box> }
      </Box>
    </Box>
  );
}
