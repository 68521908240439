import { Typography } from "@mui/material";
import React from "react";
import { MultiSelectQuestion } from "../../../types/Question";
import OptionsEditor from "../common/OptionsEditor";

export default function MultiSelectEditor(props: {
  question: MultiSelectQuestion;
}) {
  return <OptionsEditor question={props.question} />;
}
