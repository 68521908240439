import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import {
  DropDownQuestion,
  MatrixMultiSelectQuestion,
  MatrixSingleSelectQuestion,
  MultiSelectQuestion,
  QuestionType,
  RankingQuestion,
  SingleSelectQuestion,
  SortResponsesEnum,
} from '../../types/Question';
import { updateQuestion } from '../surveydesigner/SurveyDesignerSlice';

export default function SortResponsesProperty(props: {
  question:
    | SingleSelectQuestion
    | MultiSelectQuestion
    | DropDownQuestion
    | RankingQuestion
    | MatrixSingleSelectQuestion
    | MatrixMultiSelectQuestion;
}) {
  const dispatch = useAppDispatch();

  const statement = props.question.type == QuestionType.MatrixSingleSelect ? 'Sort statements' : 'Sort responses';

  //Predefined answers overrides this.
  let overridden = false;
  if (
    props.question.type == QuestionType.SingleSelect ||
    props.question.type == QuestionType.DropDown ||
    props.question.type == QuestionType.Slider ||
    props.question.type == QuestionType.MatrixSingleSelect
  ) {
    if ((props.question as SingleSelectQuestion).is_predefined_responses) overridden = true;
  }

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        display: 'flex',
        borderTop: 1,
        borderColor: '#F2F2F2',
        p: 1,
        m: 1,
      }}
    >
      <Grid item sm={6}>
        <Typography>{statement}</Typography>
      </Grid>
      <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Select
          value={overridden ? SortResponsesEnum.None : props.question.sort_responses}
          disabled={overridden}
          onChange={(e: any) => {
            const updatedQ = { ...props.question, sort_responses: e.target.value };
            dispatch(updateQuestion(updatedQ));
          }}
        >
          <MenuItem value={SortResponsesEnum.None}>Same order</MenuItem>
          <MenuItem value={SortResponsesEnum.Randomize}>Randomize</MenuItem>
          <MenuItem value={SortResponsesEnum.Alphabetical}>Sorted</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
}
