import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import { MatrixMultiSelectQuestion, QuestionOption, SortResponsesEnum } from '../../../types/Question';

import { Checkbox, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { sortOptions } from '../common/utils';

type MatrixMultiSelectPreviewState = {
  options: QuestionOption[];
  slots: QuestionOption[];
  slot_id_open: number;
  sorting: SortResponsesEnum;
  add_other: boolean;
  add_noneoftheabove: boolean;
  preview_options: QuestionOption[];
  preview_selected_values: number[][];
  preview_is_other_selected: boolean[];
};

function getPreviewStateFromQuestion(question: MatrixMultiSelectQuestion): MatrixMultiSelectPreviewState {
  const originalOptions = question.options;
  return {
    options: originalOptions,
    slots: question.slots,
    slot_id_open: question.slots.length > 0 ? 0 : -1,
    sorting: question.sort_responses,
    add_other: question.add_other,
    add_noneoftheabove: question.add_noneoftheabove,
    preview_options: sortOptions(originalOptions, question.sort_responses),
    preview_selected_values: question.slots.map((a) => []),
    preview_is_other_selected: question.slots.map((a) => false),
  };
}

function isEqual(a: MatrixMultiSelectPreviewState, b: MatrixMultiSelectPreviewState): boolean {
  return (
    a.options.length == b.options.length &&
    a.slots.length == b.slots.length &&
    a.sorting == b.sorting &&
    a.add_other == b.add_other &&
    a.add_noneoftheabove == b.add_noneoftheabove &&
    a.options.every((aOpt) => aOpt.name == b.options.find((_) => _.id == aOpt.id)?.name) &&
    a.slots.every((aSlot) => aSlot.name == b.slots.find((_) => _.id == aSlot.id)?.name)
  );
}

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function MatrixMultiSelectPreview(props: { question: MatrixMultiSelectQuestion }) {
  // Our state of the options.
  const [previewState, setPreviewState] = useState<MatrixMultiSelectPreviewState>(
    getPreviewStateFromQuestion(props.question),
  );

  //If anything changes, reset the options
  const newState = getPreviewStateFromQuestion(props.question);
  if (!isEqual(previewState, newState)) {
    setPreviewState(newState);
  }

  return (
    <Box>
      <Box sx={{ m: 1 }}>
        {previewState.slots.map((_, i) => (
          <Accordion
            key={'question_' + props.question.id + '_accordion_' + i}
            sx={{ background: '#EEEEEE' }}
            expanded={previewState.slot_id_open == i}
            onClick={(e) => {
              // Allow them to go back.
              // if (i < previewState.slot_id_open)
              setPreviewState({
                ...previewState,
                slot_id_open: i,
              });
            }}
          >
            <AccordionSummary> {_.name}</AccordionSummary>
            <AccordionDetails>
              <FormGroup
                onMouseDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  e.stopPropagation();
                }}
              >
                {previewState.preview_options.map((__, j) => (
                  <FormControlLabel
                    sx={{ backgroundColor: '#FFFFFF', m: 1 }}
                    key={`${_.id}_${__.id}`}
                    control={
                      <Checkbox
                        value={__.id}
                        checked={previewState.preview_selected_values[i].includes(__.id)}
                        onChange={(e) => {
                          const updatedSelection = [...previewState.preview_selected_values];
                          updatedSelection[i].push(__.id);
                          const updatedIsOther = [...previewState.preview_is_other_selected];
                          if (e.target.checked) updatedIsOther[i] = false;

                          setPreviewState({
                            ...previewState,
                            preview_is_other_selected: updatedIsOther,
                            preview_selected_values: updatedSelection,
                          });
                        }}
                      />
                    }
                    label={__.name}
                  />
                ))}
                {props.question.add_noneoftheabove && (
                  <FormControlLabel
                    sx={{ backgroundColor: '#FFFFFF', m: 1 }}
                    control={
                      <Checkbox
                        name="none_of_the_above"
                        checked={previewState.preview_is_other_selected[i]}
                        onChange={(e) => {
                          const updatedIsOther = [...previewState.preview_is_other_selected];
                          updatedIsOther[i] = e.target.checked;
                          const updatedSelection = [...previewState.preview_selected_values];
                          if (e.target.checked) updatedSelection[i] = [];
                          setPreviewState({
                            ...previewState,
                            preview_is_other_selected: updatedIsOther,
                            preview_selected_values: updatedSelection,
                          });
                        }}
                      />
                    }
                    label="None of the above"
                  />
                )}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
