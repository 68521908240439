import { Box, Grid, Input, Switch, TextField, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { NumericOpenEndQuestion } from '../../../types/Question';

import {
  setHasMultiple,
  setSliderMaximum,
  setSliderMinimum,
  updateQuestion,
} from '../../surveydesigner/SurveyDesignerSlice';

export default function NumericOpenEndProperties(props: { question: NumericOpenEndQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Multiple answers</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setHasMultiple({ questionId: props.question.id, value }))}
            checked={props.question.has_multiple}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Allow decimal numbers</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch checked={props.question.is_decimal_allowed}
            onChange={() => {
              // Update the question.
              const updatedQ = { ...props.question, is_decimal_allowed: !props.question.is_decimal_allowed };
              dispatch(updateQuestion(updatedQ));
            }}></Switch>
        </Grid>
      </Grid>

      {/* <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Answer unit</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch></Switch>
        </Grid>
      </Grid> */}

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Limit number range</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            checked={props.question.is_number_range_limited}
            onChange={() => {
              // Update the question.
              const updatedQ = { ...props.question, is_number_range_limited: !props.question.is_number_range_limited };
              dispatch(updateQuestion(updatedQ));
            }}
          />
        </Grid>
      </Grid>

      {props.question.is_number_range_limited ? (
        <Grid
          container
          alignItems="center"
          sx={{
            display: 'flex',
            p: 1,
            m: 1,
          }}
        >
          <Grid item sm={6}>
            <Typography>Minimum</Typography>
          </Grid>
          <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              type="number"
              variant="standard"
              value={props.question.minimum}
              onChange={(event) =>
                dispatch(setSliderMinimum({ questionId: props.question.id, value: parseFloat(event.target.value) }))
              }
            ></TextField>
          </Grid>
          <Grid item sm={6}>
            <Typography>Maximum</Typography>
          </Grid>
          <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              type="number"
              variant="standard"
              value={props.question.maximum}
              onChange={(event) =>
                dispatch(setSliderMaximum({ questionId: props.question.id, value: parseFloat(event.target.value) }))
              }
            ></TextField>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
}
