import { MenuItem, Select } from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import { getPredefinedResponsesForQuestion } from '../../types/PredefinedResponses';
import {
  DropDownQuestion,
  MatrixSingleSelectQuestion,
  QuestionType,
  SingleSelectQuestion,
  SliderQuestion,
} from '../../types/Question';
import { updateQuestion } from '../surveydesigner/SurveyDesignerSlice';

export default function PredefinedResponsesSelector(props: {
  question: SingleSelectQuestion | DropDownQuestion | SliderQuestion | MatrixSingleSelectQuestion;
}) {
  const dispatch = useAppDispatch();

  let statement = 'Predefined responses';
  switch (props.question.type) {
    case QuestionType.Slider:
      statement = 'Predefined scales';
      break;
    case QuestionType.MatrixSingleSelect:
      statement = 'Predefined scale points';
      break;
  }

  const responseChoices = getPredefinedResponsesForQuestion(props.question.type);

  return (
    // <Grid
    //   container
    //   alignItems="center"
    //   sx={{
    //     display: 'flex',
    //     borderTop: 1,
    //     borderColor: '#F2F2F2',
    //     p: 1,
    //     m: 1,
    //   }}
    // >
    //   <Grid item sm={6}>
    //     <Typography>{statement}</Typography>
    //   </Grid>
    //   <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Select
      value={props.question.predefined_response_id}
      fullWidth
      onChange={(e: any) => {
        const updatedQ = { ...props.question, predefined_response_id: e.target.value };
        dispatch(updateQuestion(updatedQ));
      }}
    >
      {responseChoices?.responses.map((resp) => (
        <MenuItem value={resp.id}>{resp.name}</MenuItem>
      ))}
    </Select>
    //   </Grid>
    // </Grid>
  );
}
