import { call, put, select } from 'redux-saga/effects';
import { setPage } from '../../appNavigationSlice';
import { setSurvey } from '../../features/surveydesigner/SurveyDesignerSlice';
import { selectSelectedTeam, setStatus } from '../../features/user/teamsSlice';
import { selectUser } from '../../features/user/userSlice';
import { apiCreateSurvey, apiDeleteSurvey, apiUpdateSurvey } from '../../services/surveysAPI';
// eslint-disable-next-line sort-imports
import { createSurveyData, Survey } from '../../types/Surveys';
import { Team } from '../../types/Teams';
import { User } from '../../types/User';
import * as actions from '../actions';

/**
 * Create a new survey.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* createSurvey(action: ReturnType<typeof actions.createSurvey>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const createSurveyRequest = {
      title: action.payload.title,
      published: false,
      teamId: action.payload.teamId,
      data: btoa(JSON.stringify(createSurveyData())),
    };

    const response = yield call(apiCreateSurvey, createSurveyRequest);

    // Get the teams again.
    const user = (yield select(selectUser)).user;
    yield put(actions.getTeams(user));

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Now change to the new survey.
    const team = (yield select(selectSelectedTeam)) as Team;
    const survey = team.surveys?.find((s) => s.id == response.data.id);
    // console.log(team.surveys);
    // console.log(`Looking for ID ${response.data.id}`);
    if (survey) {
      yield put(setSurvey(survey));
      yield put(setPage('editing'));
    }
  } catch (e) {
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Update a survey.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* updateSurvey(action: ReturnType<typeof actions.updateSurvey>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    console.log(action.payload);

    const data = {
      id: action.payload.id,
      title: action.payload.title,
      status: action.payload.status,
      published: action.payload.published,
      data: btoa(JSON.stringify(action.payload.data)),
    };
    // const data = { ...action.payload, data: btoa(JSON.stringify(action.payload.data)) };

    console.log(data);

    const response = yield call(apiUpdateSurvey, data as unknown as Survey);
    console.log(response.data);

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Delete a survey.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* deleteSurvey(action: ReturnType<typeof actions.deleteSurvey>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const response = yield call(apiDeleteSurvey, action.payload);

    // Get the teams again.
    const user = (yield select(selectUser)) as User;
    yield put(actions.getTeams(user));

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
