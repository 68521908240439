import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { useAppDispatch } from '../../app/hooks';

const steps = ['AUDIENCE', 'QUESTIONNAIRE', 'SUBMIT', 'REVIEW'];

export function BreadCrumb() {
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = React.useState(1);

  return (
    <Box
      sx={{
        // mt: "80px",
        py: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>{' '}
    </Box>
  );
}
