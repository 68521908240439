import { Cancel } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { QuestionOption, RankingQuestion } from '../../../types/Question';

export default function RankingBoxSlot(props: {
  question: RankingQuestion;
  selectedOption?: QuestionOption;
  rankIndex: number;
  isDragging: boolean;
  cancelSlotHandler: any;
}) {
  const theme = useTheme();
  const isOpenSlot = props.selectedOption == undefined;

  return (
    <Box
      sx={{
        // borderStyle: isOpenSlot ? "dotted" : "none",
        // borderWidth: 1,
        background: isOpenSlot ? theme.palette.background.default : theme.palette.secondary.main,
        color: isOpenSlot ? null : theme.palette.background.default,
        borderRadius: 2,
        padding: 1,
        margin: 1,
        textAlign: 'center',
        overflow: 'auto',
      }}
    >
      <Typography>{isOpenSlot ? props.rankIndex + 1 : props.selectedOption?.name} </Typography>
      {!isOpenSlot ? (
        <IconButton
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
          onClick={() => props.cancelSlotHandler(props.rankIndex)}
        >
          <Cancel></Cancel>
        </IconButton>
      ) : null}
    </Box>
  );
}
