import { grey, orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// REF: https://mui.com/customization/theming/
// What you get by default: https://mui.com/customization/default-theme/

// TODO: Work with team to make a theme: https://bareynol.github.io/mui-theme-creator/
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=006064&secondary.color=43A047

// APPLICATION: To use this in any component:
//  import { useTheme } from '@mui/styles';
//  function DeepChild() {
//    const theme = useTheme();
//    return <span>{`spacing ${theme.spacing}`}</span>;
//  }

// Additional theming properties.
declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface ThemeOptions {
    additional?: {
      highlighted?: string;
      modified?: string;
      logo?: string;
      lightBorder?: string;
    };
  }
}

export const nomiaTheme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: '#85cbcb',
      light: '#e7fafa',
      dark: '#56a6a6',
    },
    secondary: {
      main: '#ffe2a7',
      light: '#fff9ec',
      dark: '#ffd683',
    },
    background: {
      default: '#FDFDFD',
      // light: '#FDFDFD',
      // dark: '#010101',
    },
    success: {
      main: '#00C482',
    },
    text: {
      primary: '#6D7278',
    },
    error: {
      main: '#ff8383',
    },
    warning: {
      main: '#FF9933',
    },
    info: {
      main: '#29CFFF',
    },
    divider: '#D4CEFF',
  },
  typography: {
    fontFamily: './fonts/Graphik-Regular.otf',
  },
  //Not used yet.
  additional: {
    highlighted: orange[500],
    modified: orange[500],
    logo: grey[800],
    lightBorder: '#f2f2f2',
  },
  /*
  props: {
    MuiAppBar: {
      color: 'secondary',
    },
  },*/
});
