import { ContentCut, Delete, Edit } from '@mui/icons-material';
import BoltIcon from '@mui/icons-material/BoltOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import useActions, { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setPage } from '../../../appNavigationSlice';
import { Form, Survey } from '../../../types/Surveys';
import { setSurvey } from '../../surveydesigner/SurveyDesignerSlice';
import { selectSelectedTeam } from '../../user/teamsSlice';
import { selectUser } from '../../user/userSlice';

function statusToColor(status: string) {
  switch (status) {
    case 'DRAFT':
      return 'info';
    case 'REVIEW':
      return 'error';
    case 'PUBLISHED':
      return 'success';
    case 'DELETED':
      return 'warning';
  }
}

export default function FormCard(props: { form: Form }) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userState = useAppSelector(selectUser);
  const selectedTeam = useAppSelector(selectSelectedTeam);

  const actions = useActions();

  function handleOpenMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleEditSurvey() {
    // Set the survey and then change the page.
    // dispatch(setSurvey(props.form));
    dispatch(setPage('editing'));
    setAnchorEl(null);
  }

  function handleDeleteSurvey() {
    actions.deleteSurvey(props.form.id);
    setAnchorEl(null);
  }

  return (
    <Card sx={{ backgroundColor: '#fffff', mb: 3 }}>
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', p: 2 }}>
            {/* <BoltIcon fontSize="large" /> */}
            <img width="200px" src={props.form.imageUrl}/>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <CardHeader
            title={props.form.name}
            subheader={props.form.description}
            action={
              <Box sx={{ display: 'flex' }}>
                <IconButton>
                  <FavoriteBorderIcon />
                </IconButton>
              </Box>
            }
          />
          <CardContent sx={{ py: 0, px: 2 }}>
            <Grid container alignContent="center">
              <Grid item xs={2}>
                <b>Created: </b> {props.form.created}
                {/* <b>Created: </b> {new Date(props.form.created).toLocaleString()} */}
              </Grid>
              <Grid item xs={2}>
                {/* <b>Launched: </b> TBD */}
              </Grid>
              <Grid item xs={2}>
                <b>Closed: </b> TBD
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                Completed: 12
              </Grid>
              <Grid item xs={2} alignItems="center" justifyContent="center">
                <Button color={statusToColor(props.form.status)}>{props.form.status}</Button>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <MoreVertIcon />
                </IconButton>
              </Grid>

              {/* Next row */}
              <Grid item xs={4}>
                <b>Owner: </b> {props.form.boatOwner} ({props.form.ownerSubscription} subscription)
              </Grid>
              <Grid item xs={3}>
                <b>Branded Domain: </b> FloRidaTours
              </Grid>
              <Grid item xs={4}>
                <b>Customer Link: </b> <Link href='http://www.google.com'>Link to Form</Link>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem key="edit" onClick={() => handleEditSurvey()}>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Edit</Typography>
        </MenuItem>
        {userState.user?.hopsAdmin && (
          <MenuItem key="review">
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <Typography textAlign="center">Review</Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem key="delete" onClick={() => handleDeleteSurvey()}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Delete</Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
}
