// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAppDispatch } from '../../app/hooks';

import { Question, QuestionType } from '../../types/Question';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/**
 * The main survey editor.
 *
 * @export
 * @return {*}
 */
export default function QuestionTypeChanger(props: { selectedQuestion: Question }) {
  const dispatch = useAppDispatch();

  return (
    <FormControl fullWidth sx={{ mr: 1, p: 2 }}>
      <InputLabel>Question Type</InputLabel>
      <Select
        disabled={true}
        value={props.selectedQuestion.type}
        label="Question Type"
        // onChange={handleChange}
      >
        <MenuItem value={QuestionType.SingleSelect}>Single Select</MenuItem>
        <MenuItem value={QuestionType.MultiSelect}>Multiple Select</MenuItem>
        <MenuItem value={QuestionType.Ranking}>Ranking</MenuItem>
        <MenuItem value={QuestionType.NumericOpenEnd}>Numeric Open-End</MenuItem>
        <MenuItem value={QuestionType.OpenEnd}>Open-End</MenuItem>
        <MenuItem value={QuestionType.DropDown}>Dropdown</MenuItem>
        <MenuItem value={QuestionType.Slider}>Slider</MenuItem>
        <MenuItem value={QuestionType.StarRating}>Star Rating</MenuItem>
        <MenuItem value={QuestionType.MatrixSingleSelect}>Matrix Single Select</MenuItem>
        <MenuItem value={QuestionType.AutoSum}>AutoSum</MenuItem>
      </Select>
    </FormControl>
  );
}
