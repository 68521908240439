import { Grid, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import {
  DropDownQuestion,
  MatrixSingleSelectQuestion,
  QuestionType,
  SingleSelectQuestion,
  SliderQuestion,
} from '../../types/Question';
import { updateQuestion } from '../surveydesigner/SurveyDesignerSlice';
import PredefinedResponsesSelector from './PredefinedResponsesSelector';

export default function PredefinedResponsesProperty(props: {
  question: SingleSelectQuestion | DropDownQuestion | SliderQuestion | MatrixSingleSelectQuestion;
}) {
  const dispatch = useAppDispatch();
  // const [confirmOpen, setConfirmOpen] = React.useState(false);

  let statement = 'Predefined responses';
  switch (props.question.type) {
    case QuestionType.Slider:
      statement = 'Predefined scales';
      break;
    case QuestionType.MatrixSingleSelect:
      statement = 'Predefined scale points';
      break;
  }

  function onPredefinedResponsesChanged(selected: boolean) {
    const updatedQ = { ...props.question, is_predefined_responses: selected };
    dispatch(updateQuestion(updatedQ));
  }

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        display: 'flex',
        borderTop: 1,
        borderColor: '#F2F2F2',
        p: 1,
        m: 1,
      }}
    >
      <Grid item sm={6}>
        <Typography>{statement}</Typography>
      </Grid>
      <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Switch
          checked={props.question.is_predefined_responses}
          onChange={(e: any) => onPredefinedResponsesChanged(e.target.checked)}
        ></Switch>
      </Grid>
      <Grid item sm={12}>
        {props.question.is_predefined_responses ? <PredefinedResponsesSelector question={props.question} /> : null}
      </Grid>
    </Grid>
  );
}
