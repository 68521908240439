import { Button, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import useActions, { useAppDispatch } from '../../app/hooks';
import { Question, QuestionType } from '../../types/Question';
import { Survey } from '../../types/Surveys';
import { getQuestionIcon, getQuestionReadableType } from '../questions/common/utils';
import { addQuestion } from './SurveyDesignerSlice';
// import { useAppDispatch, useAppSelector } from "../../app/hooks";

/**
 * The footer for the survey designer.
 *
 * @export
 * @return {*}
 */
export default function SurveyDesignerFooter(props: { survey: Survey; questions: Question[] }) {
  const dispatch = useAppDispatch();
  const actions = useActions();

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

  function addQuestionToSurvey(question_type: QuestionType) {
    dispatch(addQuestion(question_type));
    setAnchorElMenu(null);
  }

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ m: 1 }}>Questionnaire path length: {props.questions.length}</Typography>
      <Button variant="outlined" sx={{ m: 1 }}>
        Import from library...
      </Button>
      <Button
        color="primary"
        variant="contained"
        sx={{ m: 1 }}
        onClick={(event) => {
          // TODO: Just for testing.
          const newSurvey = { ...props.survey, data: { ...props.survey.data, questions: props.questions } };
          actions.updateSurvey(newSurvey);
        }}
      >
        Save
      </Button>
      <Button
        color="primary"
        variant="contained"
        sx={{ m: 1 }}
        onClick={(event) => setAnchorElMenu(event.currentTarget)}
      >
        Add new question...
      </Button>

      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(anchorElMenu)}
        onClose={() => setAnchorElMenu(null)}
      >
        {/* Note that you need to filter the enum because, well, #Javascript
         */}
        {Object.values(QuestionType)
          .filter((v) => !isNaN(Number(v)))
          .map((questionTypeIndex) => {
            const questionType = questionTypeIndex as QuestionType;
            return (
              <MenuItem key={`${questionType}`} onClick={() => addQuestionToSurvey(questionType)}>
                <ListItemIcon>{getQuestionIcon(questionType)}</ListItemIcon>
                <Typography textAlign="center">{getQuestionReadableType(questionType)}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
}
