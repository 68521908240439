import { KeyboardArrowDown } from '@mui/icons-material';
import BoltIcon from '@mui/icons-material/Bolt';
import { Button, Grid } from '@mui/material';
import Box from '@mui/system/Box';
import { useState } from 'react';
import CreateSurveyForm from './CreateSurveyForm';

export default function FormsHeaderBar() {
  const [createSurveyFormOpen, setCreateSurveyFormOpen] = useState<boolean>(false);

  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid item xs={10}>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
          {/* TODO: Build this out */}
          <Button
            startIcon={<BoltIcon />}
            endIcon={<KeyboardArrowDown />}
            variant="contained"
            color="primary"
            size="large"
          >
            <b>Lorem Ipsum</b>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ p: 1 }}>
        <Button
          fullWidth
          endIcon={<KeyboardArrowDown />}
          color="secondary"
          variant="contained"
          size="large"
          onClick={(e) => setCreateSurveyFormOpen(true)}
        >
          <b>Create Form</b>
        </Button>
      </Grid>

      {createSurveyFormOpen && <CreateSurveyForm handleClose={() => setCreateSurveyFormOpen(false)} />}
    </Grid>
  );
}
