import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { RootState } from './app/store';

interface AppNavigationSlice {
  page: 'home' | 'settings' | 'editing';
  // editingSurvey: Survey | undefined;
}

const initialState: AppNavigationSlice = {
  page: 'home',
  // editingSurvey: undefined,
};

export const appNavigationSlice = createSlice({
  name: 'home',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPage: (state, action: PayloadAction<'home' | 'settings' | 'editing'>) => {
      state.page = action.payload;
    },
    // setEditingSurvey: (state, action: PayloadAction<Survey | undefined>) => {
    //   state.editingSurvey = action.payload;
    //   state.page = action.payload !== undefined ? 'editing' : 'home';
    // },
  },
});

export const { setPage } = appNavigationSlice.actions;

//Selectors
export const selectAppNavigation = (state: RootState) => state.appNavigation;

export default appNavigationSlice.reducer;
