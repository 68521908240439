import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Slide } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../app/hooks';

import { LightbulbOutlined } from '@mui/icons-material';
import { Question } from '../../types/Question';
import { getQuestionEditorControl } from '../questions/common/utils';
import BestPracticesPopover from './BestPracticesPopover';
import QuestionTitle from './QuestionTitle';

//REF: https://codesandbox.io/s/vertical-list-dgy2q?fontsize=14&hidenavigation=1&theme=dark

export default function SurveyQuestion(props: {
  question: Question;
  selected: boolean;
  onClick: any;
  dragProvided: any;
}) {
  const [expanded, setExpanded] = React.useState(true);
  const [bestPracticesAnchorEl, setBestPracticesAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Box sx={{ p: 2 }} ref={props.dragProvided.innerRef} {...props.dragProvided.draggableProps}>
        <Card
          sx={{
            m: 1,
            marginLeft: props.selected ? '-40px' : '0px',
            marginRight: props.selected ? '40px' : '0px',
          }}
          key={props.question.id}
          onClick={() => {
            props.onClick();
          }}
          elevation={props.selected ? 6 : 3}
        >
          <Grid container>
            <Grid item xs={1}>
              <Box sx={{ display: 'flex', p: 1 }}>
                <IconButton {...props.dragProvided.dragHandleProps}>
                  <DragIndicatorIcon />
                </IconButton>
                <IconButton onClick={(e) => setExpanded(!expanded)}>
                  {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <CardHeader
                title={<QuestionTitle question={props.question}></QuestionTitle>}
                action={
                  <Box sx={{ display: 'flex' }}>
                    <IconButton>
                      <FavoriteBorderIcon />
                    </IconButton>
                    <IconButton onClick={(e) => setBestPracticesAnchorEl(e.currentTarget)}>
                      <LightbulbOutlined />
                    </IconButton>
                  </Box>
                }
              />
              <CardContent sx={{ py: 0, px: 1 }}>
                <Collapse in={expanded}>{getQuestionEditorControl(props.question)}</Collapse>
              </CardContent>
            </Grid>
          </Grid>
        </Card>

        {bestPracticesAnchorEl ? (
          <BestPracticesPopover
            anchorEl={bestPracticesAnchorEl}
            parentCloseHandler={() => setBestPracticesAnchorEl(null)}
            question={props.question}
          ></BestPracticesPopover>
        ) : null}
      </Box>
    </Slide>
  );
}
