import { QuestionOption, QuestionType } from './Question';

export interface PredefinedResponse {
  id: number;
  name: string;
  options: QuestionOption[];
}

export interface PredefinedResponses {
  id: number;
  question_type: QuestionType;
  responses: PredefinedResponse[];
}

// Temporary
const predefinedResponses = new Map<QuestionType, PredefinedResponses>();

predefinedResponses.set(QuestionType.SingleSelect, {
  id: 0,
  question_type: QuestionType.SingleSelect,
  responses: [
    {
      id: 0,
      name: 'Agree/Disagree',
      options: [
        {
          id: 1001,
          name: 'Strongly Agree',
        },
        {
          id: 1002,
          name: 'Somewhat Agree',
        },
        {
          id: 1003,
          name: 'Neither Agree or Disagree',
        },
        {
          id: 1004,
          name: 'Somewhat Disagree',
        },
        {
          id: 1005,
          name: 'Strongly Disagree',
        },
      ],
    },
    {
      id: 1,
      name: 'Importance',
      options: [
        {
          id: 2001,
          name: 'Extremely Important',
        },
        {
          id: 2002,
          name: 'Very Important',
        },
        {
          id: 2003,
          name: 'Somewhat Important',
        },
        {
          id: 2004,
          name: 'Not at all important',
        },
      ],
    },
  ],
});
predefinedResponses.set(QuestionType.DropDown, {
  id: 0,
  question_type: QuestionType.DropDown,
  responses: [
    {
      id: 0,
      name: 'Agree/Disagree',
      options: [
        {
          id: 3001,
          name: 'Strongly Agree',
        },
        {
          id: 3002,
          name: 'Somewhat Agree',
        },
        {
          id: 3003,
          name: 'Neither Agree or Disagree',
        },
        {
          id: 3004,
          name: 'Somewhat Disagree',
        },
        {
          id: 3005,
          name: 'Strongly Disagree',
        },
      ],
    },
    {
      id: 1,
      name: 'Importance',
      options: [
        {
          id: 4001,
          name: 'Extremely Important',
        },
        {
          id: 4002,
          name: 'Very Important',
        },
        {
          id: 4003,
          name: 'Somewhat Important',
        },
        {
          id: 4004,
          name: 'Not at all important',
        },
      ],
    },
  ],
});

predefinedResponses.set(QuestionType.Slider, {
  id: 0,
  question_type: QuestionType.Slider,
  responses: [
    {
      id: 0,
      name: 'Agree/Disagree',
      options: [
        {
          id: 5001,
          name: 'Strongly Agree',
        },
        {
          id: 5002,
          name: 'Somewhat Agree',
        },
        {
          id: 5003,
          name: 'Neither Agree or Disagree',
        },
        {
          id: 5004,
          name: 'Somewhat Disagree',
        },
        {
          id: 5005,
          name: 'Strongly Disagree',
        },
      ],
    },
    {
      id: 1,
      name: 'Importance',
      options: [
        {
          id: 6001,
          name: 'Extremely Important',
        },
        {
          id: 6002,
          name: 'Very Important',
        },
        {
          id: 6003,
          name: 'Somewhat Important',
        },
        {
          id: 6004,
          name: 'Not at all important',
        },
      ],
    },
  ],
});

predefinedResponses.set(QuestionType.MatrixSingleSelect, {
  id: 0,
  question_type: QuestionType.MatrixSingleSelect,
  responses: [
    {
      id: 0,
      name: 'Agree/Disagree',
      options: [
        {
          id: 7001,
          name: 'Strongly Agree',
        },
        {
          id: 7002,
          name: 'Somewhat Agree',
        },
        {
          id: 7003,
          name: 'Neither Agree or Disagree',
        },
        {
          id: 7004,
          name: 'Somewhat Disagree',
        },
        {
          id: 7005,
          name: 'Strongly Disagree',
        },
      ],
    },
    {
      id: 1,
      name: 'Importance',
      options: [
        {
          id: 8001,
          name: 'Extremely Important',
        },
        {
          id: 8002,
          name: 'Very Important',
        },
        {
          id: 8003,
          name: 'Somewhat Important',
        },
        {
          id: 8004,
          name: 'Not at all important',
        },
      ],
    },
  ],
});

export function getPredefinedResponsesForQuestion(questionType: QuestionType) {
  return predefinedResponses.get(questionType);
}
export function getPredefinedResponses(questionType: QuestionType, id: number | null) {
  if (id == null) return undefined;
  return getPredefinedResponsesForQuestion(questionType)?.responses.find((resp) => resp.id == id);
}
