import { SwapVert } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AppBar, Button, Divider, MenuItem, Select, Toolbar, Typography } from '@mui/material';

export default function SurveysBar(props: {
  onSelectFilter: any;
  currentFilter: 'all' | 'draft' | 'under_review' | 'approved' | 'launched' | 'closed' | 'deleted';
}) {
  return (
    <AppBar position="sticky" color="transparent" elevation={0} sx={{ backgroundColor: '#ffffff' }}>
      <Toolbar variant="dense">
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'all' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('all')}
        >
          All
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'draft' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('draft')}
        >
          Draft
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'under_review' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('under_review')}
        >
          Under Review
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'approved' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('approved')}
        >
          Approved
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'launched' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('launched')}
        >
          Launched
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'closed' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('closed')}
        >
          Closed
        </Button>
        <Button
          sx={{ mx: 1, textTransform: 'none' }}
          color={props.currentFilter == 'deleted' ? 'primary' : 'inherit'}
          onClick={(e) => props.onSelectFilter('deleted')}
        >
          Deleted
        </Button>

        {/* SPACER TO RIGHT */}
        <Typography component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}></Typography>
        <Button startIcon={<FilterListIcon />} color="inherit">
          Filter
        </Button>
        <Divider orientation="vertical" sx={{ my: 1.5 }} flexItem />
        <Button startIcon={<SwapVert />} color="inherit">
          Sort by
        </Button>
        <Select size="small" label="Sort by" value={0}>
          <MenuItem key={0} value={0}>
            Newest
          </MenuItem>
          <MenuItem key={1} value={1}>
            Oldest
          </MenuItem>
          <MenuItem key={2} value={2}>
            Latest updated
          </MenuItem>
        </Select>
      </Toolbar>
    </AppBar>
  );
}
