import { Box } from '@mui/material';
import { RankingQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function RankingEditor(props: { question: RankingQuestion }) {
  return (
    <Box>
      <p>
        <i>Options</i>
      </p>
      <OptionsEditor question={props.question} />
    </Box>
  );
}
