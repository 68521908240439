import { Box, Typography } from '@mui/material';
import { SingleSelectQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function SingleSelectEditor(props: { question: SingleSelectQuestion }) {
  return (
    <Box>
      <Typography>
        <i>Options</i>
      </Typography>
      <OptionsEditor question={props.question} />
    </Box>
  );
}
