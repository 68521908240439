import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';

import { MultiSelectQuestion, QuestionOption, SortResponsesEnum } from '../../../types/Question';
import { sortOptions } from '../common/utils';

/**
 * The preview of the Multi Select question type.
 *
 * @export
 * @return {*}
 */
export default function MultiSelectPreview(props: { question: MultiSelectQuestion }) {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [noneChecked, setNoneChecked] = useState<boolean>(false);
  const [otherChecked, setOtherChecked] = useState<boolean>(false);
  const [options, setOptions] = useState<QuestionOption[]>(
    sortOptions(props.question.options, props.question.sort_responses),
  );
  //Keep track of the sorting...
  const [sortResponsesSelection, setSortResponsesSelection] = useState<SortResponsesEnum>(
    props.question.sort_responses,
  );

  //If anything changes, reset the options
  const originalOptionsSorted = sortOptions(props.question.options, props.question.sort_responses);
  if (
    sortResponsesSelection !== props.question.sort_responses ||
    options.length !== props.question.options.length
    //Equal length, so check items for equality (resort to keys for equality)
    // options.sort((o) => o.id).some((o, i) => originalOptionsSorted.sort((o) => o.id)[i] !== o)
  ) {
    setSortResponsesSelection(props.question.sort_responses);
    setOptions(originalOptionsSorted);
  }

  function onChangeChecked(optionId: number, checked: boolean) {
    if (!checked) setSelectedIds(selectedIds.filter((id) => id !== optionId));
    else setSelectedIds([...selectedIds, optionId]);
    if (noneChecked) setNoneChecked(false);
    if (otherChecked) setOtherChecked(false);
  }

  function onNoneOfTheAboveChanged(checked: boolean) {
    if (checked) {
      setSelectedIds([]);
      setOtherChecked(false);
      setNoneChecked(true);
    }
  }

  function onOtherChanged(checked: boolean) {
    if (checked) {
      setSelectedIds([]);
      setNoneChecked(false);
    }
    setOtherChecked(checked);
  }

  return (
    <Box sx={{ m: 1 }}>
      <FormControl component="fieldset" variant="standard" sx={{ p: 1, width: '100%' }}>
        <FormGroup>
          {options.map((_, i) => (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              key={_.id}
              control={
                <Checkbox
                  checked={selectedIds.includes(_.id)}
                  onChange={(e) => onChangeChecked(_.id, e.target.checked)}
                />
              }
              label={_.name}
            />
          ))}
          {props.question.add_noneoftheabove && (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              control={
                <Checkbox
                  name="none_of_the_above"
                  checked={noneChecked}
                  onChange={(e) => onNoneOfTheAboveChanged(e.target.checked)}
                />
              }
              label="None of the above"
            />
          )}
          {props.question.add_other && (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              control={
                <Checkbox name="other" checked={otherChecked} onChange={(e) => onOtherChanged(e.target.checked)} />
              }
              label="Other"
            />
          )}
          {otherChecked ? (
            <Box sx={{ my: 1 }}>
              <TextField sx={{ backgroundColor: '#FFFFFF' }} fullWidth placeholder="Please specify"></TextField>
            </Box>
          ) : null}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
