import { Divider, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';

import { AutoSumQuestion } from '../../../types/Question';

type AutoSumState = {
  text: string[];
  numbers: number[];
  errors: boolean[];
};

function initState(question: AutoSumQuestion) {
  return {
    text: question.options.map(() => '0'),
    numbers: question.options.map(() => 0),
    errors: question.options.map(() => false),
  };
}

/**
 * The preview of the AutoSum question type.
 *
 * @export
 * @return {*}
 */
export default function AutoSumPreview(props: { question: AutoSumQuestion }) {
  const [autosumState, setAutoSumState] = useState<AutoSumState>(initState(props.question));

  if (autosumState.numbers.length !== props.question.options.length) {
    initState(props.question);
  }

  function onChangeNumber(index: number, newValue: string) {
    const number = Number(newValue);
    const error =
      number == undefined ||
      Number.isNaN(number) ||
      number < 0 ||
      (!props.question.is_decimal_allowed && Math.round(number) - number != 0);
    if (!error) autosumState.numbers[index] = props.question.is_decimal_allowed ? number : Math.round(number);
    autosumState.text[index] = newValue;
    autosumState.errors[index] = error;
    setAutoSumState({ ...autosumState });
  }

  return (
    <Box>
      <Box sx={{ p: 1 }}>
        <Box>
          {props.question.options.map((_: any, index: number) => (
            <Box sx={{ p: 1 }}>
              <InputLabel>{_.name}</InputLabel>
              <TextField
                fullWidth
                // variant="filled"
                helperText={autosumState.errors[index] ? 'Error' : null}
                value={autosumState.text[index]}
                error={autosumState.errors[index]}
                onChange={(e) => onChangeNumber(index, e.target.value)}
                sx={{ background: '#FFFFFF', width: '100%' }}
              ></TextField>
            </Box>
          ))}
        </Box>
        <Divider orientation="horizontal" sx={{ p: 1 }} />
        <Typography textAlign={'center'} sx={{ pt: 1 }}>
          Total:{' '}
          <b>
            {autosumState.numbers.length > 0 ? autosumState.numbers.reduce((p, c) => p + Math.abs(c)) : 0} /{' '}
            {props.question.total}
          </b>
        </Typography>
      </Box>
    </Box>
  );
}
