import { Box } from '@mui/material';
import { DropDownQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function DropDownEditor(props: { question: DropDownQuestion }) {
  if (props.question.has_multiple) {
    return (
      <Box>
        <p>
          <i>Options</i>
        </p>
        <OptionsEditor question={props.question} />
        <p>
          <i>Topics</i>
        </p>
        <OptionsEditor question={props.question} useSlots={true} />
      </Box>
    );
  } else {
    return (
      <Box>
        <p>
          <i>Options</i>
        </p>
        <OptionsEditor question={props.question} />
      </Box>
    );
  }
}
