import { Box, Typography } from '@mui/material';
import { AutoSumQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function AutoSumEditor(props: { question: AutoSumQuestion }) {
  return (
    <Box>
      <Typography>
        <i>Topics</i>
      </Typography>
      <OptionsEditor question={props.question} />
    </Box>
  );
}
