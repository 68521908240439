import { useState } from 'react';
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useAppDispatch } from '../../../app/hooks';

import { Grid, IconButton, TextField } from '@mui/material';
import { MatrixSingleSelectQuestion, Question, QuestionOption } from '../../../types/Question';

import { deleteOption, deleteSlot, setOption, setSlot } from '../../surveydesigner/SurveyDesignerSlice';

function zeroize(num: number): string {
  if (num >= 0 && num < 10) {
    return '0' + num;
  } else {
    return '' + num;
  }
}

export default function OptionEditor(props: {
  question: Question;
  index: number;
  optionOrSlot: QuestionOption;
  useSlots?: boolean;
  readOnly?: boolean;
}) {
  const [textValue, setTextValue] = useState<string>(props.optionOrSlot.name);
  const dispatch = useAppDispatch();

  function commitChanges() {
    if (props.useSlots) {
      dispatch(
        setSlot({
          questionId: props.question.id,
          slotId: props.optionOrSlot.id,
          name: textValue,
        }),
      );
    } else {
      dispatch(
        setOption({
          questionId: props.question.id,
          optionId: props.optionOrSlot.id,
          name: textValue,
        }),
      );
    }
  }

  return (
    <Grid container sx={{ alignItems: 'center' }} onBlur={() => commitChanges()}>
      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
        {zeroize(props.index + 1)}
      </Grid>
      <Grid item xs={7}>
        <TextField
          fullWidth
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          onKeyUp={(event) => {
            if (!event.shiftKey && event.key == 'Enter') {
              commitChanges();
            }
          }}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton disabled={props.readOnly}>
          <ImageOutlinedIcon />
        </IconButton>
        {/* Disable these if this is a slot */}
        <IconButton disabled={props.useSlots || props.index == 0 || props.readOnly}>
          <ArrowUpwardOutlinedIcon />
        </IconButton>
        <IconButton disabled={props.useSlots || props.index == props.question.options.length - 1 || props.readOnly}>
          <ArrowDownwardOutlinedIcon />
        </IconButton>
        <IconButton
          // Only enable deletion of last slot if this is a slot.
          disabled={
            (props.useSlots && props.index !== (props.question as MatrixSingleSelectQuestion).slots.length - 1) ||
            props.readOnly
          }
          onClick={() => {
            if (props?.useSlots) {
              dispatch(
                deleteSlot({
                  questionId: props.question.id,
                  slotId: props.optionOrSlot.id,
                }),
              );
            } else {
              dispatch(
                deleteOption({
                  questionId: props.question.id,
                  optionId: props.optionOrSlot.id,
                }),
              );
            }
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
