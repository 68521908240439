// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch } from '../../app/hooks';

import { Question } from '../../types/Question';

import { copyQuestion, deleteQuestion } from '../surveydesigner/SurveyDesignerSlice';

import { Grid, IconButton, Typography } from '@mui/material';

/**
 * The main survey editor.
 *
 * @export
 * @return {*}
 */
export default function LeftQuestionHeader(props: { selectedQuestion: Question }) {
  const dispatch = useAppDispatch();

  return (
    <Grid container alignItems="center" sx={{ display: 'flex', my: 1 }}>
      <Grid item sm={6}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Q{props.selectedQuestion.id}
        </Typography>
      </Grid>
      <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton>
          <ContentCopyIcon
            onClick={() =>
              dispatch(
                copyQuestion({
                  questionId: props.selectedQuestion === undefined ? -1 : props.selectedQuestion.id,
                }),
              )
            }
          />
        </IconButton>
        <IconButton>
          <DeleteIcon
            onClick={() =>
              dispatch(
                deleteQuestion({
                  questionId: props.selectedQuestion === undefined ? -1 : props.selectedQuestion.id,
                }),
              )
            }
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}
