import jwt_decode from "jwt-decode";

export interface AuthToken {
  aud: string;
  iss: string;
  client_id: string;
  name: string;
  email: string;
  exp: number;
}

export function getLoginUrl(
  idp: string = process.env.COGNITO_IDP ??
    "tripintu.auth.us-east-2.amazoncognito.com",
  clientId: string = process.env.COGNITO_CLIENTID ??
    "47o5rmtl3b30u3gbpumqod456i"
) {
  // Build the redirect dynamically
  let redirect = "";
  if (typeof window !== `undefined`) {
    redirect = `${window.location.protocol}//${window.location.host}/callback`;
  }
  return `https://${idp}/login?response_type=token&client_id=${clientId}&redirect_uri=${redirect}&state=STATE&scope=openid+profile`;
}

export function setAccessToken(token: string) {
  if (typeof window !== `undefined`) {
    window.localStorage.setItem("accessToken", token);
  }
  return null;
}

export function setIDToken(token: string): AuthToken | null {
  if (typeof window !== `undefined`) {
    window.localStorage.setItem("idToken", token);
    return getIDTokenDecoded();
  }
  return null;
}

export function logOut() {
  if (typeof window !== `undefined`) {
    window.localStorage.removeItem("idToken");
    window.localStorage.removeItem("accessToken");
  }
}

export function getAccessToken(
  issuer: string = process.env.COGNITO_ISSUER ??
    "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_xa5CJJEpS",
  clientId: string = process.env.COGNITO_CLIENTID ??
    "47o5rmtl3b30u3gbpumqod456i"
) {
  let token = null;
  if (typeof window !== `undefined`) {
    token = window.localStorage.getItem("accessToken");
  }
  if (token == null) return null;
  // Basic validation that the token is legit.
  // Can pull out the header and check the signature but rather leave that for the API.
  const jwt = jwt_decode<AuthToken>(token);
  if (jwt["iss"] != issuer) return null;
  if (jwt["exp"] < Date.now() / 1000) return null;
  if (jwt["client_id"] != clientId) return null;
  return token;
}

export function getIDToken(
  issuer: string = process.env.COGNITO_ISSUER ??
    "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_xa5CJJEpS",
  clientId: string = process.env.COGNITO_CLIENTID ??
    "47o5rmtl3b30u3gbpumqod456i"
) {
  let token = null;
  if (typeof window !== `undefined`) {
    token = window.localStorage.getItem("idToken");
  }
  if (token == null) return null;
  // Basic validation that the token is legit.
  // Can pull out the header and check the signature but rather leave that for the API.

  try {
    const jwt = jwt_decode<AuthToken>(token);
    if (jwt["aud"] != clientId) return null;
    if (jwt["iss"] != issuer) return null;
    if (jwt["exp"] < Date.now() / 1000) return null;
  } catch (err) {
    console.error(err);
    return null;
  }
  return token;
}

export function getIDTokenDecoded(
  issuer: string = process.env.COGNITO_ISSUER ?? 
    "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_xa5CJJEpS",
  clientId: string = process.env.COGNITO_CLIENTID ??
    "47o5rmtl3b30u3gbpumqod456i"
): AuthToken | null {
  let token = null;
  if (typeof window !== `undefined`) {
    token = window.localStorage.getItem("idToken");
  }
  if (token == null) return null;
  // Basic validation that the token is legit.
  // Can pull out the header and check the signature but rather leave that for the API.
  const jwt = jwt_decode<AuthToken>(token);
  if (jwt["aud"] != clientId) return null;
  if (jwt["iss"] != issuer) return null;
  if (jwt["exp"] < Date.now() / 1000) return null;
  return jwt;
}

// Not needed unless we're going to check the signature client-side.
// If we care about signatures, get and cache the jwk, make sure the signature matches as with
// https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
// and https://github.com/auth0/node-jsonwebtoken
export function getJwk(
  issuer: string = process.env.COGNITO_ISSUER ??
    "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_xa5CJJEpS"
) {
  return `${issuer}/.well-known/jwks.json`;
}
