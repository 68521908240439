import Box from '@mui/system/Box';
import { useState } from 'react';
import SurveyList from './SurveyList';
import SurveysBar from './SurveysBar';

export default function SurveysPanel() {
  const [currentFilter, setCurrentFilter] = useState<
    'all' | 'draft' | 'under_review' | 'approved' | 'launched' | 'closed' | 'deleted'
  >('all');

  return (
    <Box>
      <SurveysBar
        currentFilter={currentFilter}
        onSelectFilter={(v: 'all' | 'draft' | 'under_review' | 'approved' | 'launched' | 'closed' | 'deleted') =>
          setCurrentFilter(v)
        }
      />
      <Box sx={{ maxHeight: '75vh', overflowY: 'auto' }}>
        <SurveyList statusFilter={currentFilter} />
      </Box>
    </Box>
  );
}
