import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { StarRatingQuestion } from "../../../types/Question";

import { useAppDispatch } from "../../../app/hooks";

import { Rating } from "@mui/material";

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function StarRatingPreview(props: {
  question: StarRatingQuestion;
}) {
  return (
    <Box>
      <Box sx={{ p: 1, textAlign: "center" }}>
        {!props.question.has_multiple && (
          <Box>
            <Rating size="large"/>
          </Box>
        )}
        {props.question.has_multiple && (
          <Box>
            {props.question.options.map((_) => (
              <Box key={props.question.id + "_StarRating_box" + _.id}>
                <InputLabel key={props.question.id + "_StarRating_il" + _.id}>
                  {_.name}
                </InputLabel>
                <Rating size="large"/>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
