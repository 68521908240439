import React from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { QuestionOption, RankingQuestion } from "../../../types/Question";
import { AddCircle } from "@mui/icons-material";

export default function RankingBoxOption(props: {
  question: RankingQuestion;
  option: QuestionOption;
  slotIndex?: number;
  isDragging: boolean;
  addOptionHandler: any;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: props.isDragging
          ? theme.palette.secondary.main
          : theme.palette.background.default,
        color: props.isDragging ? theme.palette.background.default : null,
        borderRadius: 2,
        padding: 1,
        margin: 1,
        textAlign: "center",
        overflow: "auto",
      }}
    >
      <Typography>{props.option.name} </Typography>
      {props.slotIndex === undefined ? (
        <IconButton
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          onClick={() => props.addOptionHandler(props.option.id)}
        >
          <AddCircle></AddCircle>
        </IconButton>
      ) : null}
      {/* Slot indicator */}
      {props.slotIndex !== undefined ? (
        <Button
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
          }}
        >
          {props.slotIndex}
        </Button>
      ) : null}
    </Box>
  );
}
