import { Box, Typography } from '@mui/material';
import { StarRatingQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function StarRatingEditor(props: { question: StarRatingQuestion }) {
  if (props.question.has_multiple) {
    return (
      <Box>
        <Typography>Topics</Typography>
        <OptionsEditor question={props.question} />
      </Box>
    );
  } else {
    return null;
  }
}
