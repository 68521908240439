import Box from '@mui/system/Box';
import { useAppSelector } from '../../../app/hooks';
import { selectSelectedTeam, selectTeams } from '../../user/teamsSlice';
import { Form } from '../../../types/Surveys';
import FormCard from './FormCard';

const formsOriginal: Form[] = [
  {
    id: 0,
    name: 'Example yacht 1',
    description: 'Example boat preferences and information.',
    status: 'PUBLISHED',
    boatOwner: 'Kevin Rogers',
    ownerSubscription: 'Pro',
    location: 'Miami, FL',
    published: true,
    version: '2.0',
    collectedResults: '67',
    imageUrl: 'yachts/superyacht.jpg',
    created: '04 January 2022',
    launched: '07 January 2022',
    closed: null
  },
  {
    id: 0,
    name: 'Example yacht 2',
    description: 'Example boat preferences and information.',
    status: 'REVIEW',
    boatOwner: 'Teal Henry',
    ownerSubscription: 'Basic',
    location: 'Ft. Lauderdale, FL',
    published: true,
    version: '2.0',
    collectedResults: '40',
    imageUrl: 'yachts/sailingyacht.jpg',
    created: '04 January 2022',
    launched: '07 January 2022',
    closed: null
  },
  {
    id: 0,
    name: 'Example yacht 3',
    description: 'Example boat preferences and information.',
    status: 'DRAFT',
    boatOwner: 'Taylor Rain',
    ownerSubscription: 'Basic',
    location: 'Cabo San Lucas, MX',
    published: true,
    version: '3.0',
    collectedResults: '24',
    imageUrl: 'yachts/smallyacht.jpg',
    created: '04 January 2022',
    launched: '07 January 2022',
    closed: null
  },
  {
    id: 1,
    name: 'Example yacht 4',
    description: 'Example boat preferences and information.',
    status: 'PUBLISHED',
    boatOwner: 'James Eyre',
    ownerSubscription: 'Pro',
    location: 'Miami, FL',
    published: true,
    version: '1.0',
    collectedResults: '54',
    imageUrl: 'yachts/superyacht.jpg',
    created: '04 January 2022',
    launched: '07 January 2022',
    closed: null
  },
];

export default function SurveyList(props: {
  statusFilter: 'all' | 'draft' | 'under_review' | 'approved' | 'launched' | 'closed' | 'deleted';
}) {
  const teamsState = useAppSelector(selectTeams);
  const selectedTeamState = useAppSelector(selectSelectedTeam);

  let forms = formsOriginal;

  //TODO: Refactor to one enum.
  switch (props.statusFilter) {
    case 'all':
      break;
    case 'draft':
      forms = forms?.filter((s) => s.status == 'DRAFT');
      break;
    case 'under_review':
      forms = forms?.filter((s) => s.status == 'REVIEW');
      break;
    case 'launched':
      forms = forms?.filter((s) => s.status == 'PUBLISHED');
      break;
    //TODO: Approved, closed, and deleted need to be fixed.
    case 'approved':
      forms = [];
      break;
    case 'closed':
      forms = [];
      break;
    case 'deleted':
      forms = forms?.filter((s) => s.status == 'PUBLISHED');
      break;
    // case 'under_review':
    //   surveys = surveys?.filter((s) => s.status == 'REVIEW');
    //   break;
  }

  // Now sort them.
  if (forms) forms = [...forms!].sort((a, b) => Date.parse(b.created) - Date.parse(a.created));

  return (
    <Box sx={{ backgroundColor: '#f2f2f2', p: 5, overflowY: 'hidden' }}>
      {forms?.map((form) => (
        <FormCard key={form.id} form={form} />
      ))}
    </Box>
  );
}
