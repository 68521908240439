import Box from '@mui/material/Box';
import FormsHeaderBar from './header/FormsHeaderBar';
import SearchBox from './header/SearchBox';
import LeftBar from './leftbar/LeftBar';
import SurveysPanel from './surveypanel/SurveysPanel';

// TODO: Refine this layout with UI developer.

export default function HomeContainer() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          p: 2,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '#f2f2f2',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            width: '20vw',
          }}
        >
          <SearchBox />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <FormsHeaderBar />
        </Box>
      </Box>

      {/* Center screen. */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          borderTopColor: '#f2f2f2',
          height: '80vh'
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            width: '20vw',
            height: '80vh',
          }}
        >
          <LeftBar />
        </Box> */}
        <Box sx={{ flexGrow: 1, backgroundColor: '#f2f2f2' }}>
          <SurveysPanel></SurveysPanel>
        </Box>
      </Box>
    </Box>
  );
}
