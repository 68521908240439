import { Question } from './Question';
import { TeamSummary } from './Teams';

export interface CreateSurveyRequest {
  title: string;
  published: boolean;
  teamId: number;
  data: string;
}

export interface Form {
  id: number;
  name: string;
  description: string; 
  status: 'DRAFT' | 'REVIEW' | 'PUBLISHED' | 'DELETED';
  boatOwner: string;
  ownerSubscription: string;
  published: boolean;
  location: string;
  version: string;
  collectedResults: string;
  imageUrl: string;
  created: string;
  launched: string | null;
  closed: string | null;
  team?: TeamSummary;
}

export interface Survey {
  id: number;
  title: string;
  status: 'DRAFT' | 'REVIEW' | 'PUBLISHED' | 'DELETED';
  published: boolean;
  created: string;
  launched: string | null;
  closed: string | null;
  data: SurveyData;
  team: TeamSummary;
}

export interface SurveyData {
  audience: number;
  questions: Question[];
  currentStep: 'audience' | 'design';
}

export function createSurveyData(): SurveyData {
  return {
    audience: 0,
    questions: [],
    currentStep: 'audience',
  };
}
