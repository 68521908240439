import { Box, Grid, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { SingleSelectQuestion } from '../../../types/Question';
import PredefinedResponsesProperty from '../../leftproperties/PredefinedResponsesProperty';

import SortResponsesProperty from '../../leftproperties/SortResponsesProperty';
import { setAddOther, setNoneOfTheAbove } from '../../surveydesigner/SurveyDesignerSlice';

export default function SingleSelectProperties(props: { question: SingleSelectQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <PredefinedResponsesProperty question={props.question} />
      <SortResponsesProperty question={props.question} />

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Add "None of the above"</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setNoneOfTheAbove({ questionId: props.question.id, value }))}
            checked={props.question.add_noneoftheabove}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Add "Other"</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setAddOther({ questionId: props.question.id, value }))}
            checked={props.question.add_other}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 2,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Apply logic</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          TBD
        </Grid>
      </Grid>
    </Box>
  );
}
