import React from 'react';
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAppDispatch } from '../../../app/hooks';

import { Grid, TextField } from '@mui/material';
import { Question } from '../../../types/Question';

import { addOption, addSlot } from '../../surveydesigner/SurveyDesignerSlice';

export default function OptionAdder(props: { question: Question; useSlots?: boolean }) {
  const dispatch = useAppDispatch();
  const [option, setOption] = React.useState('');

  return (
    <Grid container sx={{ p: 0.5, alignItems: 'center' }}>
      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
      <Grid item xs={7}>
        <TextField
          placeholder="Type next answer"
          fullWidth
          size="small"
          variant="standard"
          onChange={(event) => {
            setOption(event.target.value);
          }}
          value={option}
          onKeyUp={(event) => {
            if (event.key == 'Enter') {
              if (props.useSlots !== null && props.useSlots) {
                dispatch(
                  addSlot({
                    questionId: props.question!.id,
                    value: option,
                  }),
                );
              } else {
                dispatch(
                  addOption({
                    questionId: props.question!.id,
                    value: option,
                  }),
                );
              }
              setOption('');
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
