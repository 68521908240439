import { Box, Typography } from '@mui/material';
import { SliderQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function SliderEditor(props: { question: SliderQuestion }) {
  if (props.question.has_multiple) {
    return (
      <Box>
        <Typography>Topics</Typography>
        <OptionsEditor question={props.question} />
      </Box>
    );
  } else {
    return null;
  }
}
