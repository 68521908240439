import { Box, Divider, Typography } from '@mui/material';
import { MatrixMultiSelectQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function MatrixMultiSelectEditor(props: { question: MatrixMultiSelectQuestion }) {
  return (
    <Box>
      <Typography>
        <i>Topics</i>
      </Typography>
      <OptionsEditor question={props.question} useSlots={true} />
      <Divider sx={{ margin: '20px' }}></Divider>

      <Typography>
        <i>Options</i>
      </Typography>
      <OptionsEditor question={props.question} />
    </Box>
  );
}
