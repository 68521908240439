import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';

import { DropDownQuestion } from '../../../types/Question';

import { useAppDispatch } from '../../../app/hooks';

import { getPredefinedResponses } from '../../../types/PredefinedResponses';
import { sortOptions } from '../common/utils';

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function DropDownPreview(props: { question: DropDownQuestion }) {
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState('-1');

  let options = props.question.options;
  if (props.question.is_predefined_responses) {
    const response = getPredefinedResponses(props.question.type, props.question.predefined_response_id);
    if (response !== undefined) options = response?.options;
    else options = [];
  } else {
    // Not predefined, we can sort them.
    options = sortOptions(props.question.options, props.question.sort_responses);
  }

  return (
    <Box>
      <Box sx={{ p: 1, textAlign: 'center' }}>
        {!props.question.has_multiple && (
          <Select
            sx={{ padding: '10px', background: '#FFFFFF', width: '100%' }}
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
          >
            <MenuItem value="-1">Please select</MenuItem>
            {options.map((_, i) => (
              <MenuItem value={_.id}>{_.name}</MenuItem>
            ))}
          </Select>
        )}
        {props.question.has_multiple && (
          <Box>
            {props.question.slots.map((_) => (
              <Box>
                <InputLabel>{_.name}</InputLabel>
                <Select
                  sx={{ padding: '10px', background: '#FFFFFF', width: '100%' }}
                  value={selectedValue}
                  onChange={(event) => setSelectedValue(event.target.value)}
                >
                  <MenuItem value="-1">Please select</MenuItem>
                  {sortOptions(props.question.options, props.question.sort_responses).map((_, i) => (
                    <MenuItem key={_.id} value={_.id}>
                      {_.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
