import Box from '@mui/material/Box';
import React from 'react';

import { Alert, Grid, Popover, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import { Question } from '../../types/Question';
import { setMediaAttached } from '../surveydesigner/SurveyDesignerSlice';
import { FileUpload, FileUploadProps } from './FileUpload';

/**
 * Add, edit, and remove media from a question.
 * This control is embedded in the QuestionProperties.
 *
 * @export
 * @return {*}
 */
export default function MediaEditor(props: { question: Question }) {
  const dispatch = useAppDispatch();
  const ref = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const fileUploadProp: FileUploadProps = {
    accept: 'image/*',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        if (ref.current !== undefined) setAnchorEl(ref.current!);
        // setFile(event.target.files[0]);
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      if (ref.current !== undefined) setAnchorEl(ref.current!);
      // setFile(event.dataTransfer.files[0]);
    },
  };

  return (
    <Box ref={ref}>
      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          py: 2,
          pl: 2,
          pr: 0
        }}
      >
        <Grid item sm={6}>
          <Typography>Add media to questions</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            checked={props.question.is_media_attached}
            onChange={(e) =>
              dispatch(setMediaAttached({ questionId: props.question.id, mediaAttached: e.target.checked }))
            }
          ></Switch>
        </Grid>
        {props.question.is_media_attached ? (
          <Grid item sm={12} sx={{ mt: 1, border: 1, borderColor: '#F2F2F2' }}>
            <FileUpload {...fileUploadProp}></FileUpload>
          </Grid>
        ) : null}
      </Grid>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={(e: any) => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert sx={{ p: 2 }}>Media upload complete! This will be upload once the backend supports data uploads.</Alert>
      </Popover>
    </Box>
  );
}
