import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import useActions, { useAppSelector } from '../../../app/hooks';
import { selectTeams } from '../../user/teamsSlice';

export default function CreateSurveyForm(props: { handleClose: any }) {
  const actions = useActions();
  const teamsState = useAppSelector(selectTeams);

  const [name, setName] = React.useState<string>('New Survey');

  const [nameError, setNameError] = React.useState<string>('');

  const handleCancel = () => {
    props.handleClose();
  };

  function handleSave() {
    //Create a new survey
    if (teamsState.selectedTeamId == undefined) return;
    actions.createSurvey({ title: name, teamId: teamsState.selectedTeamId! });
    props.handleClose();
  }

  const handleSurveyNameChange = (e: any) => {
    if (e.target.value.trim() === '') {
      setNameError('Invalid survey title');
    } else setNameError('');
    setName(e.target.value);
  };

  return (
    <Dialog open={true} onClose={props.handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create a new survey</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          error={nameError ? true : false}
          helperText={nameError}
          margin="dense"
          id="name"
          label="Survey Title"
          fullWidth
          variant="filled"
          placeholder="Survey Title"
          value={name}
          onChange={handleSurveyNameChange}
        />
        <Accordion sx={{ my: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Survey Templates</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Design needed here.</Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={nameError !== ''} onClick={handleSave} variant="contained">
          Create Survey
        </Button>
      </DialogActions>
    </Dialog>
  );
}
