// eslint-disable-next-line sort-imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

import {
  Condition,
  MatrixSingleSelectQuestion,
  NumericOpenEndQuestion,
  OpenEndQuestion,
  Question,
  QuestionType,
  SingleSelectQuestion,
  SliderQuestion,
} from '../../types/Question';
import { Survey } from '../../types/Surveys';

import { instantiateQuestionFromType } from '../questions/common/utils';

export interface SurveyDesignerState {
  survey: Survey | undefined;
  questions: Array<Question>;
  selectedQuestionId: number;
  logicMode: boolean;
}

const initialState: SurveyDesignerState = {
  survey: undefined,
  questions: [],
  selectedQuestionId: -1,
  logicMode: false,
};

const surveyDesignerSlice = createSlice({
  name: 'surveyDesigner',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSurvey: (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload;
      state.questions = state.survey.data.questions;
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
    setSelectedQuestionId: (
      state,
      action: PayloadAction<{
        valueId: number;
      }>,
    ) => {
      state.selectedQuestionId = action.payload.valueId;
    },
    addQuestion: (state, action: PayloadAction<QuestionType>) => {
      // Get the maxId

      const maxId = state.questions.length > 0 ? Math.max(...state.questions.map((q) => q.id)) : 0;
      // Create a new question
      const newQuestion = instantiateQuestionFromType(action.payload, maxId + 1);
      // If it exists, add it.
      if (newQuestion) {
        state.questions = [...state.questions, newQuestion];
        state.selectedQuestionId = newQuestion.id;
      }
    },
    deleteQuestion: (
      state,
      action: PayloadAction<{
        questionId: number;
      }>,
    ) => {
      state.questions = state.questions.filter((_) => _.id != action.payload.questionId);
    },
    copyQuestion: (
      state,
      action: PayloadAction<{
        questionId: number;
      }>,
    ) => {
      let maxId = 1;
      for (let i = 0; i < state.questions.length; i++) {
        if (state.questions[i].id >= maxId) {
          maxId = state.questions[i].id + 1;
        }
      }

      const qIndex = state.questions.findIndex((_) => _.id == action.payload.questionId);
      if (qIndex == -1) return;

      state.questions.splice(qIndex + 1, 0, { ...state.questions[qIndex], id: maxId });
    },
    // General function to update a whole question.
    updateQuestion: (state, action: PayloadAction<Question>) => {
      const qIndex = state.questions.findIndex((q) => q.id == action.payload.id);
      if (qIndex == -1) return;
      state.questions[qIndex] = action.payload;
    },
    setMediaAttached: (state, action: PayloadAction<{ questionId: number; mediaAttached: boolean }>) => {
      const q = state.questions.find((q) => q.id == action.payload.questionId);
      if (!q) return;
      q.is_media_attached = action.payload.mediaAttached;
    },
    setMediaUrl: (state, action: PayloadAction<{ questionId: number; mediaUrl: string }>) => {
      const q = state.questions.find((q) => q.id == action.payload.questionId);
      if (!q) return;
      q.media_url = action.payload.mediaUrl;
    },
    addOption: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: string;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        let maxId = 1;

        for (let i = 0; i < match.options.length; i++) {
          if (match.options[i].id >= maxId) {
            maxId = match.options[i].id + 1;
          }
        }

        match.options.push({ id: maxId, name: action.payload.value });

        /*
        if ((<MatrixSingleSelectQuestion>match).optionSets !== null && (<MatrixSingleSelectQuestion>match).optionSets !== undefined) {
          (<MatrixSingleSelectQuestion>match).optionSets.push([]);
        }
        */
      }
    },
    /*
    addOptionToSet: (
      state,
      action: PayloadAction<{
        questionId: number;
        setNumber: number;
        value: string;
      }>
    ) => {
      const matches = state.questions.filter(
        (_) => _.id == action.payload.questionId
      );

      if (matches.length > 0) {
        const match = <MatrixSingleSelectQuestion>matches[0];

        let maxId = 1;

        for (let i = 0; i < match.optionSets[action.payload.setNumber].length; i++) {
          if (match.optionSets[action.payload.setNumber][i].id >= maxId) {
            maxId = match.optionSets[action.payload.setNumber][i].id + 1;
          }
        }

        match.optionSets[action.payload.setNumber].push({ id: maxId, name: action.payload.value });
      }
    },
    */
    addSlot: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: string;
      }>,
    ) => {
      const q = state.questions.find((_) => _.id == action.payload.questionId);
      if (!q) return;
      const qRanking = q as MatrixSingleSelectQuestion;

      qRanking.slots.push({
        id: Math.max(...qRanking.slots.map((s) => s.id)) + 1,
        name: action.payload.value,
      });
    },
    deleteOption: (
      state,
      action: PayloadAction<{
        questionId: number;
        optionId: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        let whichI = -1;
        match.options.forEach((_, j) => {
          if (_.id == action.payload.optionId) {
            whichI = j;
          }
        });

        match.options = [...match.options.filter((_) => _.id != action.payload.optionId)];

        /*
        if (whichI >= 0 && (<MatrixSingleSelectQuestion>match).optionSets !== null && (<MatrixSingleSelectQuestion>match).optionSets !== undefined) {
          (<MatrixSingleSelectQuestion>match).optionSets.splice(whichI, 1);
        }*/
      }
    },
    deleteSlot: (
      state,
      action: PayloadAction<{
        questionId: number;
        slotId: number;
      }>,
    ) => {
      const q = state.questions.find((_) => _.id == action.payload.questionId);

      if (!q) return;

      (q as MatrixSingleSelectQuestion).slots = (q as MatrixSingleSelectQuestion).slots.filter(
        (s) => s.id !== action.payload.slotId,
      );
    },
    setTitle: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: string;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        match.title = action.payload.value;
      }
    },
    setOption: (
      state,
      action: PayloadAction<{
        questionId: number;
        optionId: number;
        name: string;
      }>,
    ) => {
      const q = state.questions.find((_) => _.id == action.payload.questionId);
      if (!q) return;
      const o = q.options.find((_) => _.id == action.payload.optionId);
      if (!o) return;
      o.name = action.payload.name;
    },
    setSlot: (
      state,
      action: PayloadAction<{
        questionId: number;
        slotId: number;
        name: string;
      }>,
    ) => {
      const q = state.questions.find((_) => _.id == action.payload.questionId);
      if (!q) return;
      // Redefine as matrix question.
      const qRank = q as MatrixSingleSelectQuestion;
      if (!qRank.slots) return;
      const s = qRank.slots.find((_) => _.id == action.payload.slotId);
      if (!s) return;
      s.name = action.payload.name;
    },

    selectAnswer: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SingleSelectQuestion>match).selectedAnswers = [action.payload.value];
      }
    },

    addSelectedAnswerId: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        //   action.payload.value,
        // ];
      }
    },
    clearSelectedAnswerId: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setNoneOfTheAbove: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: boolean;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SingleSelectQuestion>match).add_noneoftheabove = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setAddOther: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: boolean;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SingleSelectQuestion>match).add_other = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setHasMultiple: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: boolean;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<NumericOpenEndQuestion>match).has_multiple = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setResponseLength: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: string;
      }>,
    ) => {
      const match = state.questions.find((_) => _.id == action.payload.questionId);
      if (!match) return;

      (match as OpenEndQuestion).response_length = action.payload.value;
    },
    setSliderMinimum: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SliderQuestion>match).minimum = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setSliderMaximum: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SliderQuestion>match).maximum = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setSliderStep: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SliderQuestion>match).step = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    setSliderDefaultValue: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: number;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        (<SliderQuestion>match).default_value = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },
    switchQuestions: (state, action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>) => {
      //1. Remove the question
      const r = Array.from(state.questions);
      const [removed] = r.splice(action.payload.sourceIndex, 1);
      //2. Add in the new question
      r.splice(action.payload.destinationIndex, 0, removed);
      state.questions = r;
    },
    setAnswerForLogic: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: string;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        match.answer_for_logic = action.payload.value;

        // match.selectedAnswers = [
        //   ...match.selectedAnswers.filter((_) => _ != action.payload.value),
        // ];
      }
    },

    setLogicMode: (
      state,
      action: PayloadAction<{
        mode: boolean;
      }>,
    ) => {
      state.logicMode = action.payload.mode;
    },

    addCondition: (
      state,
      action: PayloadAction<{
        questionId: number;
        value: Condition;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        match.conditions.push(action.payload.value);
      }
    },

    addOptionCondition: (
      state,
      action: PayloadAction<{
        questionId: number;
        optionId: number;
        value: Condition;
      }>,
    ) => {
      const matches = state.questions.filter((_) => _.id == action.payload.questionId);

      if (matches.length > 0) {
        const match = matches[0];

        if (match.option_conditions[action.payload.optionId] === undefined) {
          match.option_conditions[action.payload.optionId] = [action.payload.value];
        } else {
          match.option_conditions[action.payload.optionId].push(action.payload.value);
        }
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //extraReducers: (builder) => {},
});

export const {
  setSurvey,
  clearQuestions,
  addCondition,
  addOptionCondition,
  addQuestion,
  copyQuestion,
  updateQuestion,
  deleteQuestion,
  addOption,
  //addOptionToSet,
  addSlot,
  setOption,
  addSelectedAnswerId,
  clearSelectedAnswerId,
  deleteOption,
  deleteSlot,
  setSlot,
  selectAnswer,
  setSelectedQuestionId,
  setMediaAttached,
  setMediaUrl,
  setResponseLength,
  setHasMultiple,
  setNoneOfTheAbove,
  setAddOther,
  setAnswerForLogic,
  setLogicMode,
  setTitle,
  switchQuestions,
  setSliderMinimum,
  setSliderMaximum,
  setSliderStep,
  setSliderDefaultValue,
} = surveyDesignerSlice.actions;

// TODO: Fix this duplication
export const selectorQuestions = (state: RootState) => state.surveyDesigner.questions;
export const selectorSurvey = (state: RootState) => state.surveyDesigner.survey;
export const selectLogicMode = (state: RootState) => state.surveyDesigner.logicMode;
export const selectorSelectedQuestion = (state: RootState) =>
  state.surveyDesigner.questions.find((q) => q.id == state.surveyDesigner.selectedQuestionId);

export default surveyDesignerSlice.reducer;
