/**
 * Enum of the various question types.
 * NOTE: This is the same order they are shown in the UI, need to maintain it.
 *
 * @export
 * @enum {number}
 */
export enum QuestionType {
  SingleSelect = 0,
  MultiSelect,
  DropDown,
  OpenEnd,
  NumericOpenEnd,
  Description,
  StarRating,
  Slider,
  Ranking,
  MatrixSingleSelect,
  MatrixMultiSelect,
  AutoSum,
}

export enum SortResponsesEnum {
  Randomize = 0,
  Alphabetical,
  None,
}

export interface QuestionOption {
  id: number;
  name: string;
}

export interface Condition {
  question_id: number;
  comparison: string;
  value: string;
}

interface Dictionary<T> {
  [Key: string]: T;
}

export interface Question {
  id: number;
  type: QuestionType;
  title: string;
  order: number;
  options: QuestionOption[];
  is_media_attached: boolean;
  media_url: string;

  answer_for_logic: string;
  conditions: Condition[];
  option_conditions: Dictionary<Condition[]>;
}

export interface SingleSelectQuestion extends Question {
  selectedAnswers: Array<number>;
  is_predefined_responses: boolean;
  predefined_response_id: number | null;
  sort_responses: SortResponsesEnum;
  add_noneoftheabove: boolean; //DONE
  add_other: boolean; //DONE
}

export function CreateSingleSelectQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  add_noneoftheabove: boolean,
  add_other: boolean,
): SingleSelectQuestion {
  const n = {
    id,
    type: QuestionType.SingleSelect,
    title,
    order,
    options,
    selectedAnswers: [],
    is_media_attached: false,
    media_url: '',
    sort_responses: SortResponsesEnum.Randomize,
    add_noneoftheabove,
    add_other,
    is_predefined_responses: false,
    predefined_response_id: null,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface MatrixSingleSelectQuestion extends Question {
  slots: Array<QuestionOption>;

  is_predefined_responses: boolean;
  predefined_response_id: number | null;
  sort_responses: SortResponsesEnum;
  add_noneoftheabove: boolean; //DONE
  add_other: boolean; //DONE
}

export function CreateMatrixSingleSelectQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  slots: Array<{ id: number; name: string }>,
  add_noneoftheabove: boolean,
  add_other: boolean,
): MatrixSingleSelectQuestion {
  const n = {
    id,
    type: QuestionType.MatrixSingleSelect,
    title,
    order,
    options,
    slots,
    selectedAnswers: [],
    is_media_attached: false,
    media_url: '',
    sort_responses: SortResponsesEnum.Randomize,
    add_noneoftheabove,
    add_other,
    is_predefined_responses: false,
    predefined_response_id: null,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface MatrixMultiSelectQuestion extends Question {
  slots: Array<QuestionOption>;
  sort_responses: SortResponsesEnum;
  add_noneoftheabove: boolean;
  add_other: boolean;
  is_limit_answers: boolean;
  limit_answer_min: number;
  limit_answer_max: number;
}

export function CreateMatrixMultiSelectQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  slots: Array<{ id: number; name: string }>,
  add_noneoftheabove: boolean,
  add_other: boolean,
): MatrixMultiSelectQuestion {
  const n = {
    id,
    type: QuestionType.MatrixMultiSelect,
    title,
    order,
    options,
    slots,
    selectedAnswers: [],
    is_media_attached: false,
    media_url: '',
    sort_responses: SortResponsesEnum.Randomize,
    add_noneoftheabove,
    add_other,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
    is_limit_answers: false,
    limit_answer_min: 0,
    limit_answer_max: 1,
  };

  return n;
}

export interface MultiSelectQuestion extends Question {
  sort_responses: SortResponsesEnum;
  add_noneoftheabove: boolean;
  add_other: boolean;
  is_limit_answers: boolean;
  limit_answer_min: number;
  limit_answer_max: number;
}

export function CreateMultiSelectQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  add_noneoftheabove: boolean,
  add_other: boolean,
): MultiSelectQuestion {
  const n = {
    id,
    type: QuestionType.MultiSelect,
    title,
    order,
    options,
    selectedAnswers: [],
    is_media_attached: false,
    media_url: '',
    sort_responses: SortResponsesEnum.Randomize,
    add_noneoftheabove,
    add_other,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
    is_limit_answers: false,
    limit_answer_min: 0,
    limit_answer_max: 1,
  };

  return n;
}

export interface RankingQuestion extends Question {
  is_custom_labels: boolean;
  sort_responses: SortResponsesEnum;
  ranking_count: number;
}

export function CreateRankingQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  is_custom_labels: boolean,
  ranking_count: number,
): RankingQuestion {
  const n = {
    id,
    type: QuestionType.Ranking,
    title,
    order,
    options,
    is_custom_labels,
    ranking_count,
    media_url: '',
    is_media_attached: false,
    sort_responses: SortResponsesEnum.Randomize,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface NumericOpenEndQuestion extends Question {
  has_multiple: boolean;
  is_decimal_allowed: boolean;
  is_number_range_limited: boolean;
  minimum: number;
  maximum: number;
}

export function CreateNumericOpenEndQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  has_multiple: boolean,
  is_decimal_allowed: boolean,
  is_number_range_limited: boolean,
  minimum: number,
  maximum: number,
): NumericOpenEndQuestion {
  const n = {
    id,
    type: QuestionType.NumericOpenEnd,
    title,
    order,
    options,
    has_multiple,
    is_decimal_allowed,
    is_number_range_limited,
    minimum,
    maximum,
    media_url: '',
    is_media_attached: false,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface DropDownQuestion extends Question {
  slots: QuestionOption[];
  sort_responses: SortResponsesEnum;
  has_multiple: boolean;
  is_predefined_responses: boolean;
  predefined_response_id: number | null;
}

export function CreateDropDownQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  has_multiple: boolean,
  slots: Array<{ id: number; name: string }>,
): DropDownQuestion {
  const n = {
    id,
    type: QuestionType.DropDown,
    title,
    order,
    options,
    has_multiple,
    slots,
    media_url: '',
    sort_responses: SortResponsesEnum.Randomize,
    is_media_attached: false,
    is_predefined_responses: false,
    predefined_response_id: null,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface OpenEndQuestion extends Question {
  response_length: string;
}

export function CreateOpenEndQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  response_length: 'short' | 'medium' | 'long',
): OpenEndQuestion {
  const n = {
    id,
    type: QuestionType.OpenEnd,
    title,
    order,
    options,
    response_length,
    media_url: '',
    is_media_attached: false,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

//  Slider question type

export interface SliderQuestion extends Question {
  has_multiple: boolean;
  minimum: number;
  maximum: number;
  step: number;
  default_value: number;
  is_predefined_responses: boolean;
  predefined_response_id: number | null;
}

export function CreateSliderQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  has_multiple: boolean,
  minimum: number,
  maximum: number,
  step: number,
  default_value: number,
): SliderQuestion {
  const n = {
    id,
    type: QuestionType.Slider,
    title,
    order,
    options,
    has_multiple,
    minimum,
    maximum,
    step,
    default_value,
    media_url: '',
    is_media_attached: false,
    is_predefined_responses: false,
    predefined_response_id: null,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

//  Star rating type

export interface StarRatingQuestion extends Question {
  has_multiple: boolean;
}

export function CreateStarRatingQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  has_multiple: boolean,
): StarRatingQuestion {
  const n = {
    id,
    type: QuestionType.StarRating,
    title,
    order,
    options,
    has_multiple,
    media_url: '',
    is_media_attached: false,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
  };

  return n;
}

export interface AutoSumQuestion extends Question {
  is_decimal_allowed: boolean;
  is_number_range_limited: boolean;
  minimum: number;
  maximum: number;
  total: number;
}

export function CreateAutoSumQuestion(
  id: number,
  title: string,
  order: number,
  options: Array<{ id: number; name: string }>,
  is_decimal_allowed: boolean,
  is_number_range_limited: boolean,
  minimum: number,
  maximum: number,
): AutoSumQuestion {
  const n = {
    id,
    type: QuestionType.AutoSum,
    title,
    order,
    options,
    is_decimal_allowed,
    is_number_range_limited,
    minimum,
    maximum,
    media_url: '',
    is_media_attached: false,
    answer_for_logic: '',
    conditions: [],
    option_conditions: {},
    total: 100,
  };

  return n;
}
