import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAppDispatch } from '../../../app/hooks';
import { getPredefinedResponses } from '../../../types/PredefinedResponses';

import { MatrixSingleSelectQuestion, Question, QuestionOption, SingleSelectQuestion } from '../../../types/Question';

import { addSlot } from '../../surveydesigner/SurveyDesignerSlice';
import OptionAdder from './OptionAdder';
import OptionEditor from './OptionEditor';

/**
 * The list of options for the question.
 * Applies to Single Select, Multiple Select, Ranking, and other quantized questions.
 *
 * @export
 * @return {*}
 */
export default function OptionsEditor(props: { question: Question; useSlots?: boolean; fixedValues?: boolean }) {
  const dispatch = useAppDispatch();

  let editedArray: QuestionOption[] | undefined = props.useSlots
    ? (props.question as MatrixSingleSelectQuestion).slots
    : props.question.options;
  let isReadOnly = false;
  // If predefined questions, then get the read-only list.
  if (!props.useSlots && (props.question as SingleSelectQuestion).is_predefined_responses) {
    isReadOnly = true;
    editedArray = getPredefinedResponses(
      props.question.type,
      (props.question as SingleSelectQuestion).predefined_response_id,
    )?.options;
  }

  if (props.useSlots) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Grid container spacing={1}> */}
        {editedArray?.map((slot, i) => (
          <OptionEditor
            key={props.question.id + '_slots_editor' + slot.id}
            question={props.question}
            index={i}
            optionOrSlot={slot}
            useSlots={props.useSlots}
          />
        ))}
        {(props.fixedValues === undefined || !props.fixedValues) && (
          <OptionAdder question={props.question} useSlots={true} />
        )}
        {props.fixedValues !== undefined && props.fixedValues && (
          <Button
            onClick={() => {
              // Add one to the end.
              dispatch(
                addSlot({
                  questionId: props.question!.id,
                  value: `${(props.question as MatrixSingleSelectQuestion).slots.length + 1}`,
                }),
              );
            }}
          >
            ADD RANK
          </Button>
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {editedArray?.map((option, i) => (
          <OptionEditor
            key={props.question.id + '_options_editor_' + option.id}
            question={props.question}
            index={i}
            optionOrSlot={option}
            readOnly={isReadOnly}
          />
        ))}
        {!isReadOnly ? <OptionAdder question={props.question} useSlots={false} /> : null}
      </Box>
    );
  }
}
