import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import type { AppDispatch, RootState } from './store';

import {
  addOption,
  addQuestion,
  addSelectedAnswerId,
  clearQuestions,
  clearSelectedAnswerId,
  createSurvey,
  deleteOption,
  deleteSurvey,
  getTeams,
  getUserSelf,
  selectAnswer,
  setSelectedQuestionId,
  updateSurvey,
} from './actions';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      getUserSelf,
      getTeams,
      createSurvey,
      updateSurvey,
      deleteSurvey,
      // Todo: Confirm we can remove
      clearQuestions,
      addQuestion,
      addOption,
      addSelectedAnswerId,
      clearSelectedAnswerId,
      deleteOption,
      selectAnswer,
      setSelectedQuestionId,
    },
    dispatch,
  );
};

export default useActions;
