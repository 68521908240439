import { call, put } from 'redux-saga/effects';
import { setMemberships, setStatus, setTeams } from '../../features/user/teamsSlice';
import { apiGetTeams } from '../../services/teamsAPI';
import { Survey } from '../../types/Surveys';
import * as actions from '../actions';

function decodeSurvey(original: Survey): Survey {
  console.log(original.data);
  const newData = JSON.parse(atob(original.data as unknown as string));
  console.log(newData);
  return { ...original, data: newData };
}

/**
 * Get the current user information.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getTeams(action: ReturnType<typeof actions.getTeams>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const response = yield call(apiGetTeams);

    // Decode all the survey data.
    const teams = [...response.data];
    for (const t of teams) {
      t.surveys = t.surveys.map((s: Survey) => decodeSurvey(s));
    }
    // console.log(teams);

    // Set the teams.
    yield put(setTeams(response.data));
    // Set the memberships from the user.
    // TODO: Make this cleaner as a single store call.
    console.log(action.payload);
    yield put(setMemberships(action.payload.teamMemberships));

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
