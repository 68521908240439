import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import { Divider, ListItemIcon } from '@mui/material';
import Button from '@mui/material/Button';
import { pink } from '@mui/material/colors';
import useActions, { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAppNavigation, setPage } from '../../appNavigationSlice';
import logo from '../common/logo_title.png';
import { selectorQuestions } from '../surveydesigner/SurveyDesignerSlice';
import { selectUser } from '../user/userSlice';
import { TeamsBar } from './TeamsBar';

//REF: https://levelup.gitconnected.com/react-custom-hook-typescript-to-download-a-file-through-api-b766046db18a

export function HeaderMenu() {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUser);
  const navState = useAppSelector(selectAppNavigation);

  const questions = useAppSelector(selectorQuestions);

  const actions = useActions();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const [anchorElDeveloper, setAnchorElDeveloper] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenDeveloperMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDeveloper(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseDeveloperMenu = () => {
    setAnchorElDeveloper(null);
  };

  return (
    // Note the z-index gracefully puts this above all the drawers, the nominal operation is a little strange.
    // REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: '#FFFFFF',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#f2f2f2', // TODO: Use theme lightBorder color.
      }}
      elevation={0}
    >
      <Toolbar>
        <Button onClick={() => dispatch(setPage('home'))}>
          <img src={logo} height="70px" style={{ marginRight: '20px' }} alt="tripintuLogo" />
        </Button>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="secondary"
            sx={{ color: '#000000' }}
          >
            <MenuIcon sx={{ color: pink[500] }} />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

        {/* Right side */}
        <Box sx={{ display: 'flex', flexGrow: 0, alignItems: 'center' }}>
          {/* TODO: Componentize this */}

          {/* <Box sx={{ display: 'flex', mx: 3, alignItems: 'center' }}>
            <Button variant="contained" color="secondary" sx={{ color: 'white' }}>
            </Button>
          </Box> */}

          {/* <Divider orientation="vertical" sx={{ my: 1.5, mx: 1 }} flexItem /> */}

          <TeamsBar />

          <Divider orientation="vertical" sx={{ my: 1.5, mx: 1 }} flexItem />

          <Box sx={{ display: 'flex', mx: 3, alignItems: 'center' }}>
            <Typography>Account Type: </Typography>
            <Button color="primary">
              <b>ADMIN</b>
            </Button>
          </Box>

          <Divider orientation="vertical" sx={{ my: 1.5, mx: 1 }} flexItem />

          {/* <Box sx={{ display: 'flex', mx: 2 }}>
            <Typography>
              Credits: <b>$10,000.00 (USD)</b>
            </Typography>
          </Box> */}

          {userState.user && userState.user.hopsAdmin && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDeveloperMenu}
              startIcon={<IntegrationInstructionsIcon />}
            >
              Analyst
            </Button>
          )}

          <Tooltip title="User Account and Settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="profile" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">User Profile</Typography>
            </MenuItem>
            <MenuItem key="logout" component={Link} to="logout">
              <Typography textAlign="center">Log Out</Typography>
            </MenuItem>
          </Menu>

          <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElDeveloper}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElDeveloper)}
            onClose={handleCloseDeveloperMenu}
          >
            <MenuItem disabled={navState.page !== 'editing'} >
              <ListItemIcon>
                <CloudDownloadIcon />
              </ListItemIcon>
              <Typography textAlign="center">Preview XML...</Typography>
            </MenuItem>
          </Menu>
          {/* <a href={url} download={name} className="hidden" ref={ref} /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
