import Box from '@mui/material/Box';
import { useAppSelector } from '../../app/hooks';
import { selectorSelectedQuestion } from '../surveydesigner/SurveyDesignerSlice';
import Preview from './Preview';

export default function RightPreviewContainer() {
  const selectedQuestion = useAppSelector(selectorSelectedQuestion);

  return (
    // Below the main bar.
    <Box
      sx={{
        display: 'inline-block',
        width: '21vw',
        float: 'right',
        minHeight: '80vh',
      }}
    >
      <Box sx={{ flexGrow: 2, width: '100%' }}>
        <Preview selectedQuestion={selectedQuestion}></Preview>
      </Box>
    </Box>
  );
}
