import { Box, Grid, Switch, TextField, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { MultiSelectQuestion, Question } from '../../../types/Question';
import SortResponsesProperty from '../../leftproperties/SortResponsesProperty';
import { setAddOther, setNoneOfTheAbove, updateQuestion } from '../../surveydesigner/SurveyDesignerSlice';

export default function MultiSelectProperties(props: { question: MultiSelectQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <SortResponsesProperty question={props.question} />

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Add "None of the above"</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setNoneOfTheAbove({ questionId: props.question.id, value }))}
            checked={props.question.add_noneoftheabove}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Add "Other"</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setAddOther({ questionId: props.question.id, value }))}
            checked={props.question.add_other}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Limit answers</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => {
              const newQ = { ...props.question, is_limit_answers: value };
              if (value) {
                //Reset the limits
                newQ.limit_answer_min = 1;
                newQ.limit_answer_max = newQ.options.length;
              }
              dispatch(updateQuestion(newQ as Question));
            }}
            checked={props.question.is_limit_answers}
          ></Switch>
        </Grid>
        {props.question.is_limit_answers ? (
          <Box display="flex">
            <Typography sx={{ p: 1 }}>At least </Typography>
            <TextField
              error={
                props.question.limit_answer_min < 0 ||
                props.question.limit_answer_min > props.question.options.length ||
                props.question.limit_answer_min > props.question.limit_answer_max
              }
              hiddenLabel
              margin="none"
              variant="filled"
              size="small"
              sx={{ width: '60px' }}
              type="number"
              value={props.question.limit_answer_min}
              onChange={(e) =>
                dispatch(updateQuestion({ ...props.question, limit_answer_min: parseInt(e.target.value) } as Question))
              }
            ></TextField>
            <Typography sx={{ p: 1 }}> No more than </Typography>
            <TextField
              error={
                props.question.limit_answer_max < 0 ||
                props.question.limit_answer_max > props.question.options.length ||
                props.question.limit_answer_max < props.question.limit_answer_max
              }
              hiddenLabel
              margin="none"
              variant="filled"
              size="small"
              sx={{ width: '60px' }}
              type="number"
              value={props.question.limit_answer_max}
              onChange={(e) =>
                dispatch(updateQuestion({ ...props.question, limit_answer_max: parseInt(e.target.value) } as Question))
              }
            ></TextField>
          </Box>
        ) : null}
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Apply logic</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          TBD
        </Grid>
      </Grid>
    </Box>
  );
}
