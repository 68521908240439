import { Box, Grid, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { DropDownQuestion } from '../../../types/Question';
import PredefinedResponsesProperty from '../../leftproperties/PredefinedResponsesProperty';

import SortResponsesProperty from '../../leftproperties/SortResponsesProperty';
import { setHasMultiple } from '../../surveydesigner/SurveyDesignerSlice';

export default function DropDownProperties(props: { question: DropDownQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <PredefinedResponsesProperty question={props.question} />
      <SortResponsesProperty question={props.question} />

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Multiple answers</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setHasMultiple({ questionId: props.question.id, value }))}
            checked={props.question.has_multiple}
          ></Switch>
        </Grid>
      </Grid>
    </Box>
  );
}
