import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { useAppDispatch } from '../../app/hooks';

import { Divider, Typography } from '@mui/material';
import { Question } from '../../types/Question';
import { getQuestionIcon } from '../questions/common/utils';

import { useState } from 'react';
import { setTitle } from './SurveyDesignerSlice';

/**
 * The title for the question.
 *
 * @export
 * @return {*}
 */
export default function QuestionTitle(props: { question: Question }) {
  const dispatch = useAppDispatch();

  const [titleChanged, setTitleChanged] = useState<string>(props.question.title);

  function getValidationMessage(text: string) {
    if (props.question.title.length > 128)
      return 'This is a very long question, you may want to consider shortening it.';
    if (props.question.title.trim().length == 0) return 'The title is empty, please update with a question.';
    return '';
  }

  const validationMessage = getValidationMessage(props.question.title);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex' }}>
        {getQuestionIcon(props.question.type)}
        <Typography sx={{ mx: 1 }}>
          <b>Q{props.question.id}</b>
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem></Divider>
      <Input
        value={titleChanged}
        fullWidth
        size="small"
        placeholder="Provide a question"
        sx={{ px: 1 }}
        onChange={(event) => setTitleChanged(event.target.value)}
        onKeyUp={(event) => {
          if (event.key == 'Enter') {
            dispatch(
              setTitle({
                questionId: props.question.id,
                value: titleChanged,
              }),
            );
          }
        }}
        onBlur={(e) => {
          if (titleChanged !== props.question.title)
            dispatch(
              setTitle({
                questionId: props.question.id,
                value: titleChanged,
              }),
            );
        }}
        // helperText={validationMessage}
        color={getValidationMessage(props.question.title) == '' ? undefined : 'warning'}
      />

      <Typography></Typography>
    </Box>
  );
}
//InputProps={{ disableUnderline: true }}
//import { useAppDispatch, useAppSelector } from "../../app/hooks";
