import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import './App.css';
import useActions, { useAppSelector } from './app/hooks';
import { selectAppNavigation } from './appNavigationSlice';
import { LoginPage } from './features/auth/LoginPage';
import { BreadCrumb } from './features/breadcrumb/BreadCrumb';
import { HeaderMenu } from './features/headermenu/HeaderMenu';
import HomeContainer from './features/home/HomeContainer';
import LeftBarContainer from './features/leftbar/LeftBarContainer';
import RightPreviewContainer from './features/rightpreview/RightPreviewContainer';
import SurveyDesignerContainer from './features/surveydesigner/SurveyDesignerContainer';
import { getIDTokenDecoded } from './services/TokenStorage';

function App() {
  const idToken = getIDTokenDecoded();
  const actions = useActions();

  React.useEffect(() => {
    // On mounting get the latest user

    actions.getUserSelf();
  }, [idToken]);

  const appNavigationState = useAppSelector(selectAppNavigation);

  if (!idToken) {
    //Show the login page.
    return <LoginPage></LoginPage>;
  }

  //TODO: Refactor this to be a clean navigator.
  let mainContent = null;
  switch (appNavigationState.page) {
    case 'home':
      mainContent = <HomeContainer />;
      break;
    case 'editing':
      mainContent = (
        <Box>
          {/* NOTE THE BREADCRUMB ANCHORS EVERYTHING FROM THE TOP */}
          <BreadCrumb></BreadCrumb>
          {/* <LeftDrawer></LeftDrawer> */}
          <RightPreviewContainer />
          <LeftBarContainer />

          <SurveyDesignerContainer />
        </Box>
      );
      break;
    case 'settings':
      mainContent = <Typography>Setting up</Typography>;
      break;
  }

  return (
    <Box>
      <CssBaseline />
      <HeaderMenu></HeaderMenu>
      <Box sx={{ mt: '80px' }}>{mainContent}</Box>
    </Box>
  );
}

export default App;
