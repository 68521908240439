import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setIDToken } from "../../services/TokenStorage";

export default function CallbackPage() {
  const navigate = useNavigate();

  const url = new URL(
    window.location.href
      .replace("callback/#", "callback/?")
      .replace("callback#", "callback?")
  );
  const token = url.searchParams.get("id_token") || "";
  const tokenDecoded = setIDToken(token);
  // console.log(tokenDecoded);

  useEffect(() => {
    navigate("/");
  }, []);

  if (tokenDecoded == null) {
    return <Alert>Error, can't decode the token.</Alert>;
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Logged in!</DialogTitle>
      <DialogContent>
        You're logged in and should be redirected, if not click below.
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
