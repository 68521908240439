import Box from "@mui/material/Box";
import { useAppSelector } from "../../app/hooks";
import Properties from "../leftproperties/Properties";
import { selectorSelectedQuestion } from "../surveydesigner/SurveyDesignerSlice";

export default function LeftBarContainer() {
  const selectedQuestion = useAppSelector(selectorSelectedQuestion);

  return (
    <Box
      sx={{
        mt: 2,
        display: "inline-block",
        width: "20vw",
        float: "left",
        minHeight: "80vh",
      }}
    >
      {/* <AppBar
        position="fixed"
        // sx={{ width: `calc(100% - 64px)`, mr: `32px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Permanent drawer
          </Typography>
          Hello
        </Toolbar>
      </AppBar> */}
      <Box sx={{ flexGrow: 0, width: "100%", minHeight: "80vh" }}>
        <Properties selectedQuestion={selectedQuestion}></Properties>
      </Box>
    </Box>
  );
}
