import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import { NumericOpenEndQuestion } from '../../../types/Question';

import { useAppDispatch } from '../../../app/hooks';

import {
  setAnswerForLogic
} from '../../surveydesigner/SurveyDesignerSlice';

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function NumericOpenEndPreview(props: { question: NumericOpenEndQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box sx={{ p: 1 }}>
        {!props.question.has_multiple && (
          <Input type="number" sx={{ padding: '10px', background: '#FFFFFF', width: '100%' }}
          
          value={props.question.answer_for_logic}
          onChange={(event) => {
            dispatch(setAnswerForLogic({
              questionId: props.question.id,
              value: event.target.value
            }))
          }}
          ></Input>
        )}
        {props.question.has_multiple && (
          <Box>
            {props.question.options.map((_) => (
              <Box sx={{ p: 1 }}>
                <InputLabel>{_.name}</InputLabel>
                <Input type="number" sx={{ padding: '10px', background: '#FFFFFF', width: '100%' }}></Input>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
