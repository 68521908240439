import { createAction } from '@reduxjs/toolkit';
import { Survey } from '../types/Surveys';
import { User } from '../types/User';

// User
export const getSelf = createAction('getSelf');
export const getUserSelf = createAction('getUserSelf');

// Teams
export const getTeams = createAction<User>('getTeams');

// Survey
export const createSurvey = createAction<{ title: string; teamId: number }>('createSurvey');
export const updateSurvey = createAction<Survey>('updateSurvey');
export const deleteSurvey = createAction<number>('deleteSurvey');

// TODO: Confirm we can remove this.
export const clearQuestions = createAction('clearQuestions');
export const addQuestion = createAction('addQuestion');
export const addOption = createAction('addOption');
export const addSelectedAnswerId = createAction('addSelectedAnswerId');
export const clearSelectedAnswerId = createAction('clearSelectedAnswerId');
export const deleteOption = createAction('deleteOption');
export const selectAnswer = createAction('selectAnswer');
export const setSelectedQuestionId = createAction('setSelectedQuestionId');
