import { Box, Divider, Typography } from '@mui/material';
import { MatrixSingleSelectQuestion } from '../../../types/Question';
import OptionsEditor from '../common/OptionsEditor';

export default function MatrixSingleSelectEditor(props: { question: MatrixSingleSelectQuestion }) {
  return (
    <Box>
      <Typography>
        <i>Topics</i>
      </Typography>
      <OptionsEditor question={props.question} useSlots={true} />
      <Divider sx={{ margin: '20px' }}></Divider>

      <Typography>
        <i>Options</i>
      </Typography>
      <OptionsEditor question={props.question} />

      {/* props.question.options.map( (_, j) => <Box>
        <Typography>{_.name} Options</Typography>
        <OptionsEditor question={props.question} targetArray={props.question.optionSets[j]} setNumber={j}/>
        <Divider sx={{ margin: "20px"}}></Divider>
  </Box>) */}
    </Box>
  );
}
