import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RankingQuestion } from '../../../types/Question';
import { sortOptions } from '../common/utils';
import RankingBoxOption from './RankingBoxOption';
import RankingBoxSlot from './RankingBoxSlot';

// type PreviewState {

// }

/**
 * The preview of the Ranking question type.
 *
 * @export
 * @return {*}
 */
export default function RankingPreview(props: { question: RankingQuestion }) {
  const [selectedOptionIds, setSelectedOptionIds] = useState<(number | undefined)[]>(
    Array.from(Array(props.question.ranking_count).keys()).map(() => undefined),
  );

  // If the number of slots has changed, update them.
  if (props.question.ranking_count !== selectedOptionIds.length)
    setSelectedOptionIds(Array.from(Array(props.question.ranking_count).keys()).map(() => undefined));

  function onDragEnd(dragEvent: any) {
    // If in-place drag+drop.
    if (dragEvent.source.droppableId == dragEvent.destination.droppableId) return;
    addSlot(parseInt(dragEvent.draggableId));
  }

  function cancelSlot(rankIndex: number) {
    // Make a new set of selectedOption IDs
    const newIds = [...selectedOptionIds];
    newIds[rankIndex] = undefined;
    setSelectedOptionIds(newIds);
  }

  function addSlot(optionId: number) {
    const openSlotIndex = selectedOptionIds.findIndex((i) => i === undefined);
    if (openSlotIndex < 0) return;
    const newIds = [...selectedOptionIds];
    newIds[openSlotIndex] = optionId;
    setSelectedOptionIds(newIds);
  }

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container>
          <Grid item xs={6}>
            {/* Left */}
            <Droppable droppableId="rankingPreviewDroppableAvailable">
              {(provided, snapshot) => (
                <Box ref={provided.innerRef}>
                  {sortOptions(props.question.options, props.question.sort_responses).map((_, i) => {
                    if (selectedOptionIds.includes(_.id)) {
                      return (
                        <RankingBoxOption
                          key={_.id}
                          isDragging={true}
                          option={_}
                          question={props.question}
                          slotIndex={
                            selectedOptionIds.includes(_.id)
                              ? selectedOptionIds.findIndex((id) => id == _.id)
                              : undefined
                          }
                          addOptionHandler={addSlot}
                        />
                      );
                    }
                    return (
                      <Draggable key={_.id} draggableId={String(_.id)} index={i}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{ position: 'relative' }}
                          >
                            <RankingBoxOption
                              isDragging={snapshot.isDragging}
                              option={_}
                              question={props.question}
                              slotIndex={
                                selectedOptionIds.includes(_.id)
                                  ? selectedOptionIds.findIndex((id) => id == _.id)
                                  : undefined
                              }
                              addOptionHandler={addSlot}
                            />
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={6}>
            <Droppable droppableId="rankingPreviewDroppableSelected">
              {(provided, snapshot) => (
                <Box ref={provided.innerRef}>
                  {Array.from(Array(props.question.ranking_count).keys()).map((i) => (
                    <Box key={`slot_${i}`} sx={{ position: 'relative' }}>
                      <RankingBoxSlot
                        isDragging={false}
                        rankIndex={i}
                        question={props.question}
                        selectedOption={
                          selectedOptionIds[i] != null
                            ? props.question.options.find((option) => option.id == selectedOptionIds[i])
                            : undefined
                        }
                        cancelSlotHandler={cancelSlot}
                      />
                    </Box>
                    //   )}
                    // </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
    </Box>
  );
}
