import { CreateSurveyRequest, Survey } from '../types/Surveys';
import { client } from './client';

export function apiCreateSurvey(survey: CreateSurveyRequest) {
  return client.post('surveys', survey);
}

export function apiUpdateSurvey(survey: Survey) {
  //Base64 encode the JSON inner data
  // const data = { ...survey, data: btoa(JSON.stringify(survey.data)) };

  return client.put(`surveys/${survey.id}`, survey);
}

export function apiDeleteSurvey(surveyId: number) {
  //Base64 encode the JSON inner data
  return client.delete(`surveys/${surveyId}`, null, { skipReturn: true });
}
