import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import AppBar from "@mui/material/AppBar";
import { grey } from "@mui/material/colors";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LightbulbOutlined } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { Question } from "../../types/Question";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  getQuestionIcon,
  getQuestionReadableType,
} from "../questions/common/utils";

import { setTitle } from "./SurveyDesignerSlice";

/**
 * The title for the question.
 *
 * @export
 * @return {*}
 */
export default function BestPracticesPopover(props: {
  question: Question;
  anchorEl: any;
  parentCloseHandler: any;
}) {
  const dispatch = useAppDispatch();

  return (
    <Popover
      open={true}
      anchorEl={props.anchorEl}
      onClose={() => props.parentCloseHandler()}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Card>
        <CardHeader
          avatar={<LightbulbOutlined color="secondary" />}
          title={
            <Typography color="secondary">
              {`Best Practices Tips for ${getQuestionReadableType(
                props.question.type
              )}`}
            </Typography>
          }
        />
        <Divider orientation="horizontal" sx={{mx:3}}/>
        <CardContent sx={{p:2}}>
          <Typography component={"p"}>
          Lorem ipsum question type sum! This is something we need to fill in.
          </Typography>
          <Typography component={"li"} sx={{mx:2}}>
            <ul>Use short sentences</ul>
            <ul>Make certain options don't overlap</ul>
            <ul>Be sparing with "other"</ul>
          </Typography>
          <Typography component={"p"}>
          This, as well as many other useful tips, are provided in our free user knowledgebase.
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained">Learn More</Button>
        </CardActions>
      </Card>
    </Popover>
  );
}
