import { Box } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { MatrixSingleSelectQuestion } from '../../../types/Question';
import PredefinedResponsesProperty from '../../leftproperties/PredefinedResponsesProperty';
import SortResponsesProperty from '../../leftproperties/SortResponsesProperty';

export default function MatrixSingleSelectProperties(props: { question: MatrixSingleSelectQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <PredefinedResponsesProperty question={props.question} />
      <SortResponsesProperty question={props.question} />
    </Box>
  );
}
