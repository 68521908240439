import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import { MatrixSingleSelectQuestion, QuestionOption, SortResponsesEnum } from '../../../types/Question';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from 'react';
import { getPredefinedResponses } from '../../../types/PredefinedResponses';
import { sortOptions } from '../common/utils';

type MatrixSingleSelectPreviewState = {
  options: QuestionOption[];
  slots: QuestionOption[];
  slot_id_open: number;
  sorting: SortResponsesEnum;
  predefined_response_id: number | null;
  preview_options: QuestionOption[];
};

function getPreviewStateFromQuestion(question: MatrixSingleSelectQuestion): MatrixSingleSelectPreviewState {
  const originalOptions = question.is_predefined_responses
    ? getPredefinedResponses(question.type, question.predefined_response_id)?.options ?? []
    : question.options;
  return {
    options: originalOptions,
    slots: question.slots,
    slot_id_open: question.slots.length > 0 ? 0 : -1,
    sorting: question.sort_responses,
    predefined_response_id: question.predefined_response_id,
    preview_options: sortOptions(originalOptions, question.sort_responses),
  };
}

function isEqual(a: MatrixSingleSelectPreviewState, b: MatrixSingleSelectPreviewState): boolean {
  return (
    a.options.length == b.options.length &&
    a.slots.length == b.slots.length &&
    a.sorting == b.sorting &&
    a.predefined_response_id == b.predefined_response_id &&
    a.options.every((aOpt) => aOpt.name == b.options.find((_) => _.id == aOpt.id)?.name) &&
    a.slots.every((aSlot) => aSlot.name == b.slots.find((_) => _.id == aSlot.id)?.name)
  );
}

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function MatrixSingleSelectPreview(props: { question: MatrixSingleSelectQuestion }) {
  // Our state of the options.
  const [previewState, setPreviewState] = useState<MatrixSingleSelectPreviewState>(
    getPreviewStateFromQuestion(props.question),
  );

  //If anything changes, reset the options
  const newState = getPreviewStateFromQuestion(props.question);
  if (!isEqual(previewState, newState)) {
    setPreviewState(newState);
  }

  return (
    <Box>
      <Box sx={{ m: 1 }}>
        {previewState.slots.map((_, i) => (
          <Accordion
            key={'question_' + props.question.id + '_accordion_' + i}
            sx={{ background: '#EEEEEE' }}
            expanded={previewState.slot_id_open == i}
            onClick={(e) => {
              // Allow them to go back.
              if (i < previewState.slot_id_open)
                setPreviewState({
                  ...previewState,
                  slot_id_open: i,
                });
            }}
          >
            <AccordionSummary> {_.name}</AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                onMouseDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  //On click advance to next question
                  setPreviewState({ ...previewState, slot_id_open: previewState.slot_id_open + 1 });
                  e.stopPropagation();
                }}
              >
                {previewState.preview_options.map((__, j) => (
                  <FormControlLabel
                    sx={{ backgroundColor: '#FFFFFF', m: 1 }}
                    key={`${_.id}_${__.id}`}
                    control={<Radio value={__.id} />}
                    label={__.name}
                  />
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
