import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./Router";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import './fonts/Montserrat-VariableFont_wght.ttf';

import { nomiaTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
// REF: https://github.com/iamhosseindhv/notistack
import { SnackbarProvider } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={nomiaTheme}>
          <SnackbarProvider maxSnack={3}>
            <DndProvider backend={HTML5Backend}>
              <Router />
            </DndProvider>
          </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
