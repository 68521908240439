
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Question, QuestionType } from '../../types/Question';

import AddIcon from "@mui/icons-material/Add";
import { Box, Divider, Grid, IconButton, Input, MenuItem, Select, Switch, Typography } from '@mui/material';
import { getQuestionPropertiesControl } from '../questions/common/utils';
import LeftQuestionHeader from './LeftQuestionHeader';
import MediaEditor from './MediaEditor';
import QuestionTypeChanger from './QuestionTypeChanger';

import { addCondition, addOptionCondition, selectLogicMode, selectorQuestions, setLogicMode } from '../surveydesigner/SurveyDesignerSlice';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import CreateIcon from '@mui/icons-material/Create';



/**
 * The main survey editor.
 *
 * @export
 * @return {*}
 */
export default function Properties(props: { selectedQuestion: Question | undefined }) {
  const dispatch = useAppDispatch();
  const logicMode: boolean = useAppSelector(selectLogicMode);
  const questions: Question[] = useAppSelector(selectorQuestions);

  const [conditionQuestionId, setConditionQuestionId ] = useState("-1");
  const [conditionComparison, setConditionComparison ] = useState("equals");
  const [conditionValue, setConditionValue ] = useState("");

  const [optionsConditionOptionId, setOptionsConditionOptionId ] = useState("-1");
  const [optionsConditionQuestionId, setOptionsConditionQuestionId ] = useState("-1");
  const [optionsConditionComparison, setOptionsConditionComparison ] = useState("equals");
  const [optionsConditionValue, setOptionsConditionValue ] = useState("");

  if (!props.selectedQuestion) {
    return (
      <Box sx={{ margin: '20px' }}>
        <Typography>No question selected.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ pl: 2, pr: 1, overflowY: "scroll", height: "80vh" }}>
      <Grid
        container
        alignItems="center"
      >
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-left' }}>
          <IconButton color={ !logicMode? "primary" : "default" } onClick={ () => dispatch(setLogicMode({mode: false}))}><CreateIcon></CreateIcon></IconButton>
          <IconButton color={ logicMode? "primary" : "default" } onClick={ () => dispatch(setLogicMode({mode: true}))}><AltRouteIcon></AltRouteIcon></IconButton>
        </Grid>
      </Grid>

      <LeftQuestionHeader selectedQuestion={props.selectedQuestion} />

      { !logicMode && 
        <Box>
          <QuestionTypeChanger selectedQuestion={props.selectedQuestion} />

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 2,
            }}
          >
            <Grid item sm={6}>
              <Typography>Recall answers</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography color="primary">@</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              py: 2,
              pl: 2,
              pr: 0,
            }}
          >
            <Grid item sm={6}>
              <Typography>Carry answers from</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Switch></Switch>
            </Grid>
          </Grid>

          <MediaEditor question={props.selectedQuestion}></MediaEditor>
        </Box> }

      { !logicMode && getQuestionPropertiesControl(props.selectedQuestion)}

      { logicMode && <Box>
        <Typography sx={{ marginTop: "10px", marginBottom: "10px", fontWeight: 'bold' }}>Conditions:</Typography>
        { props.selectedQuestion.conditions.length == 0 && 
          <Typography>There are no conditions on this question</Typography>
        }
        { props.selectedQuestion.conditions.length > 0 && 
          <Box>
            { props.selectedQuestion.conditions.map( (_, i) => 
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                { i > 0 ? "and " : ""}
                #{ _.question_id }'s value { _.comparison } { _.value }
              </Typography>
            ) }
          </Box>
        }

        <Divider sx={{ marginTop: "20px", marginBottom: "20px" }}></Divider>
        <Box>
          <Typography>Add a condition:</Typography>
          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Question ID</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                type="number"
                value={conditionQuestionId}
                onChange={(event) =>
                  setConditionQuestionId(event.target.value)
                }>
                <MenuItem value="-1">None</MenuItem>
                { questions.map( _ => 
                  <MenuItem value={_.id}>
                    #{ _.id }
                  </MenuItem>)}
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Comparison</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                type="number"
                value={conditionComparison}
                onChange={(event) =>
                  setConditionComparison(event.target.value)
                }>
                <MenuItem value="equals">Equals</MenuItem>
                <MenuItem value="is greater than">Greater Than</MenuItem>
                <MenuItem value="is greater than or equals">Greater Than or Equals</MenuItem>
                <MenuItem value="is less than">Less Than</MenuItem>
                <MenuItem value="is less than or equals">Less Than or Equals</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Value</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Input
                type="string"
                value={conditionValue}
                onChange={(event) =>
                  setConditionValue(event.target.value)
                } />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <IconButton onClick={ () => {
                if (conditionQuestionId != "-1" && conditionValue != "") {
                  dispatch(addCondition({
                    questionId: props.selectedQuestion === undefined ? 0 : props.selectedQuestion?.id, 
                    value : {
                      question_id: parseInt(conditionQuestionId),
                      comparison: conditionComparison,
                      value: conditionValue
                  }}))
                }}
              }
                >
                <AddIcon></AddIcon>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box> }

      { logicMode && props.selectedQuestion.type == QuestionType.SingleSelect && <Box>
        <Divider sx={{ marginTop: "20px", marginBottom: "20px" }}></Divider>
        <Typography sx={{ marginTop: "10px", marginBottom: "10px", fontWeight: 'bold' }}>Option Conditions:</Typography>

        { Object.keys(props.selectedQuestion.option_conditions).map( _ => 
          <Box>
            <Typography sx={{ marginTop: "10px", fontWeight: 'bold' }}>On option { _ }</Typography>
            { props.selectedQuestion!.option_conditions[_].map( __ => 
            <Typography>
              Option displays on:  #{ __.question_id } 's value { __.comparison } { __.value }
            </Typography> )
          }
          </Box>
          )}
        <Typography>Add a options condition:</Typography>
          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Option</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                type="number"
                value={optionsConditionOptionId}
                onChange={(event) =>
                  setOptionsConditionOptionId(event.target.value)
                }>
                <MenuItem value="-1">None</MenuItem>
                { props.selectedQuestion!.options.map( _ => 
                  <MenuItem value={_.id}>
                    #{ _.name }
                  </MenuItem>)}
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Question ID</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                type="number"
                value={optionsConditionQuestionId}
                onChange={(event) =>
                  setOptionsConditionQuestionId(event.target.value)
                }>
                <MenuItem value="-1">None</MenuItem>
                { questions.map( _ => 
                  <MenuItem value={_.id}>
                    #{ _.id }
                  </MenuItem>)}
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Comparison</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                type="number"
                value={optionsConditionComparison}
                onChange={(event) =>
                  setOptionsConditionComparison(event.target.value)
                }>
                <MenuItem value="equals">Equals</MenuItem>
                <MenuItem value="is greater than">Greater Than</MenuItem>
                <MenuItem value="is greater than or equals">Greater Than or Equals</MenuItem>
                <MenuItem value="is less than">Less Than</MenuItem>
                <MenuItem value="is less than or equals">Less Than or Equals</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <Typography>Value</Typography>
            </Grid>
            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Input
                type="string"
                value={optionsConditionValue}
                onChange={(event) =>
                  setOptionsConditionValue(event.target.value)
                } />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#F2F2F2',
              p: 1,
              m: 1,
            }}
            >
            <Grid item sm={6}>
              <IconButton onClick={ () =>
                {
                  if (optionsConditionOptionId != "-1" && optionsConditionQuestionId != "-1" && optionsConditionValue != "") {
                    dispatch(addOptionCondition({
                      optionId: parseInt(optionsConditionOptionId),
                      questionId: props.selectedQuestion === undefined ? 0 : props.selectedQuestion?.id, 
                      value : {
                        question_id: parseInt(optionsConditionQuestionId),
                        comparison: optionsConditionComparison,
                        value: optionsConditionValue
                    }}))
                  }
                }
              }
                >
                <AddIcon></AddIcon>
              </IconButton>
            </Grid>
          </Grid>
        </Box> }
    </Box>
  );
}
