import { Box, Grid, MenuItem, Select, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { OpenEndQuestion } from '../../../types/Question';

import { setResponseLength } from '../../surveydesigner/SurveyDesignerSlice';

export default function OpenEndProperties(props: { question: OpenEndQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Response length</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            onChange={(event) => {
              const value = event.target.value as string;
              if (value !== null && value !== undefined)
                dispatch(setResponseLength({ questionId: props.question.id, value }));
            }}
            label="Response length"
            value={props.question.response_length}
          >
            <MenuItem value={'short'}>Short</MenuItem>
            <MenuItem value={'medium'}>Medium</MenuItem>
            <MenuItem value={'long'}>Long</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
}
