import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import PercentIcon from '@mui/icons-material/Percent';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import StarRateIcon from '@mui/icons-material/StarRate';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import {
  AutoSumQuestion,
  CreateAutoSumQuestion,
  CreateDropDownQuestion,
  CreateMatrixMultiSelectQuestion,
  CreateMatrixSingleSelectQuestion,
  CreateMultiSelectQuestion,
  CreateNumericOpenEndQuestion,
  CreateOpenEndQuestion,
  CreateRankingQuestion,
  CreateSingleSelectQuestion,
  CreateSliderQuestion,
  CreateStarRatingQuestion,
  DropDownQuestion,
  MatrixMultiSelectQuestion,
  MatrixSingleSelectQuestion,
  MultiSelectQuestion,
  NumericOpenEndQuestion,
  OpenEndQuestion,
  Question,
  QuestionOption,
  QuestionType,
  RankingQuestion,
  SingleSelectQuestion,
  SliderQuestion,
  SortResponsesEnum,
  StarRatingQuestion,
} from '../../../types/Question';

import SingleSelectEditor from '../singleselect/SingleSelectEditor';
import SingleSelectPreview from '../singleselect/SingleSelectPreview';
import SingleSelectProperties from '../singleselect/SingleSelectProperties';

import MultiSelectEditor from '../multiselect/MultiSelectEditor';
import MultiSelectPreview from '../multiselect/MultiSelectPreview';
import MultiSelectProperties from '../multiselect/MultiSelectProperties';

import RankingEditor from '../ranking/RankingEditor';
import RankingPreview from '../ranking/RankingPreview';
import RankingProperties from '../ranking/RankingProperties';

import NumericOpenEndEditor from '../numericopenend/NumericOpenEndEditor';
import NumericOpenEndPreview from '../numericopenend/NumericOpenEndPreview';
import NumericOpenEndProperties from '../numericopenend/NumericOpenEndProperties';

import DropDownEditor from '../dropdown/DropDownEditor';
import DropDownPreview from '../dropdown/DropDownPreview';
import DropDownProperties from '../dropdown/DropDownProperties';

import OpenEndEditor from '../openend/OpenEndEditor';
import OpenEndPreview from '../openend/OpenEndPreview';
import OpenEndProperties from '../openend/OpenEndProperties';

import SliderEditor from '../slider/SliderEditor';
import SliderPreview from '../slider/SliderPreview';
import SliderProperties from '../slider/SliderProperties';

import StarRatingEditor from '../starrating/StarRatingEditor';
import StarRatingPreview from '../starrating/StarRatingPreview';
import StarRatingProperties from '../starrating/StarRatingProperties';

import MatrixSingleSelectEditor from '../matrixsingleselect/MatrixSingleSelectEditor';
import MatrixSingleSelectPreview from '../matrixsingleselect/MatrixSingleSelectPreview';
import MatrixSingleSelectProperties from '../matrixsingleselect/MatrixSingleSelectProperties';

import MatrixMultiSelectEditor from '../matrixmultiselect/MatrixMultiSelectEditor';
import MatrixMultiSelectPreview from '../matrixmultiselect/MatrixMultiSelectPreview';
import MatrixMultiSelectProperties from '../matrixmultiselect/MatrixMultiSelectProperties';

import AutoSumEditor from '../autosum/AutoSumEditor';
import AutoSumPreview from '../autosum/AutoSumPreview';
import AutoSumProperties from '../autosum/AutoSumProperties';

import { KeyboardDoubleArrowRight, Tag } from '@mui/icons-material';
import { Alert, AlertTitle, Tooltip } from '@mui/material';

export function getQuestionPreviewControl(question: Question) {
  switch (question.type) {
    case QuestionType.SingleSelect:
      return <SingleSelectPreview question={question as SingleSelectQuestion} />;
    case QuestionType.MultiSelect:
      return <MultiSelectPreview question={question as MultiSelectQuestion} />;
    case QuestionType.Ranking:
      return <RankingPreview question={question as RankingQuestion} />;
    case QuestionType.NumericOpenEnd:
      return <NumericOpenEndPreview question={question as NumericOpenEndQuestion} />;
    case QuestionType.DropDown:
      return <DropDownPreview question={question as DropDownQuestion} />;
    case QuestionType.OpenEnd:
      return <OpenEndPreview question={question as OpenEndQuestion} />;
    case QuestionType.Slider:
      return <SliderPreview question={question as SliderQuestion} />;
    case QuestionType.StarRating:
      return <StarRatingPreview question={question as StarRatingQuestion} />;
    case QuestionType.MatrixSingleSelect:
      return <MatrixSingleSelectPreview question={question as MatrixSingleSelectQuestion} />;
    case QuestionType.MatrixMultiSelect:
      return <MatrixMultiSelectPreview question={question as MatrixMultiSelectQuestion} />;
    case QuestionType.AutoSum:
      return <AutoSumPreview question={question as AutoSumQuestion} />;
  }
  return null;
}

export function getQuestionEditorControl(question: Question) {
  switch (question.type) {
    case QuestionType.SingleSelect:
      return <SingleSelectEditor question={question as SingleSelectQuestion} />;
    case QuestionType.MultiSelect:
      return <MultiSelectEditor question={question as MultiSelectQuestion} />;
    case QuestionType.Ranking:
      return <RankingEditor question={question as RankingQuestion} />;
    case QuestionType.NumericOpenEnd:
      return <NumericOpenEndEditor question={question as NumericOpenEndQuestion} />;
    case QuestionType.DropDown:
      return <DropDownEditor question={question as DropDownQuestion} />;
    case QuestionType.OpenEnd:
      return <OpenEndEditor question={question as OpenEndQuestion} />;
    case QuestionType.Slider:
      return <SliderEditor question={question as SliderQuestion} />;
    case QuestionType.StarRating:
      return <StarRatingEditor question={question as StarRatingQuestion} />;
    case QuestionType.MatrixSingleSelect:
      return <MatrixSingleSelectEditor question={question as MatrixSingleSelectQuestion} />;
    case QuestionType.MatrixMultiSelect:
      return <MatrixMultiSelectEditor question={question as MatrixMultiSelectQuestion} />;
    case QuestionType.AutoSum:
      return <AutoSumEditor question={question as AutoSumQuestion} />;
  }
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>The question type {question.type} does not have an editor.
    </Alert>
  );
}

export function getQuestionPropertiesControl(question: Question) {
  switch (question.type) {
    case QuestionType.SingleSelect:
      return <SingleSelectProperties question={question as SingleSelectQuestion} />;
    case QuestionType.MultiSelect:
      return <MultiSelectProperties question={question as MultiSelectQuestion} />;
    case QuestionType.Ranking:
      return <RankingProperties question={question as RankingQuestion} />;
    case QuestionType.NumericOpenEnd:
      return <NumericOpenEndProperties question={question as NumericOpenEndQuestion} />;
    case QuestionType.DropDown:
      return <DropDownProperties question={question as DropDownQuestion} />;
    case QuestionType.OpenEnd:
      return <OpenEndProperties question={question as OpenEndQuestion} />;
    case QuestionType.Slider:
      return <SliderProperties question={question as SliderQuestion} />;
    case QuestionType.StarRating:
      return <StarRatingProperties question={question as StarRatingQuestion} />;
    case QuestionType.MatrixSingleSelect:
      return <MatrixSingleSelectProperties question={question as MatrixSingleSelectQuestion} />;
    case QuestionType.MatrixMultiSelect:
      return <MatrixMultiSelectProperties question={question as MatrixMultiSelectQuestion} />;
    case QuestionType.AutoSum:
      return <AutoSumProperties question={question as AutoSumQuestion} />;
  }
  return null;
}

export function getQuestionReadableType(questionType: QuestionType) {
  switch (questionType) {
    case QuestionType.SingleSelect:
      return 'Single Select';
    case QuestionType.MultiSelect:
      return 'Multiple Select';
    case QuestionType.DropDown:
      return 'Dropdown';
    case QuestionType.OpenEnd:
      return 'Open-end';
    case QuestionType.NumericOpenEnd:
      return 'Numeric Open-End';
    case QuestionType.Description:
      return 'Description';
    case QuestionType.StarRating:
      return 'Star Ratings';
    case QuestionType.Slider:
      return 'Slider';
    case QuestionType.Ranking:
      return 'Ranking';
    case QuestionType.MatrixSingleSelect:
      return 'Single Select Matrix';
    case QuestionType.MatrixMultiSelect:
      return 'Multiple Select Matrix';
    case QuestionType.AutoSum:
      return 'AutoSum';
    default:
      return null;
  }
}

export function getQuestionIcon(question: QuestionType, color = 'primary.main') {
  let icon = null;
  switch (question) {
    case QuestionType.SingleSelect:
      icon = <RadioButtonCheckedIcon sx={{ color: color }} />;
      break;
    case QuestionType.MultiSelect:
      icon = <CheckBoxOutlinedIcon sx={{ color: color }} />;
      break;
    case QuestionType.DropDown:
      icon = <ArrowDropDownCircleIcon sx={{ color: color }} />;
      break;
    case QuestionType.OpenEnd:
      icon = <TextFieldsIcon sx={{ color: color }} />;
      break;
    case QuestionType.NumericOpenEnd:
      icon = <Tag sx={{ color: color }} />;
      break;
    case QuestionType.Description:
      icon = <FormatAlignLeftIcon sx={{ color: color }} />;
      break;
    case QuestionType.StarRating:
      icon = <StarRateIcon sx={{ color: color }} />;
      break;
    case QuestionType.Slider:
      icon = <KeyboardDoubleArrowRight sx={{ color: color }} />;
      break;
    case QuestionType.Ranking:
      icon = <SignalCellularAltOutlinedIcon sx={{ color: color }} />;
      break;
    case QuestionType.MatrixSingleSelect:
      icon = <RadioButtonCheckedIcon sx={{ color: color }} />;
      break;
    case QuestionType.MatrixMultiSelect:
      icon = <CheckBoxOutlinedIcon sx={{ color: color }} />;
      break;
    case QuestionType.AutoSum:
      icon = <PercentIcon sx={{ color: color }} />;
      break;
    default:
      return null;
  }
  return <Tooltip title={getQuestionReadableType(question) ?? 'Unknown'}>{icon}</Tooltip>;
}

export function instantiateQuestionFromType(question: QuestionType, id: number): Question | null {
  switch (question) {
    case QuestionType.SingleSelect:
      return CreateSingleSelectQuestion(id, '', id, [], false, false);
    case QuestionType.MultiSelect:
      return CreateMultiSelectQuestion(id, '', id, [], false, false);
    case QuestionType.Ranking:
      return CreateRankingQuestion(id, '', id, [], false, 3);
    case QuestionType.NumericOpenEnd:
      return CreateNumericOpenEndQuestion(id, '', id, [], false, false, false, 0, 10);
    case QuestionType.DropDown:
      return CreateDropDownQuestion(id, '', id, [], false, []);
    case QuestionType.OpenEnd:
      return CreateOpenEndQuestion(id, '', id, [], 'short');
    case QuestionType.Slider:
      return CreateSliderQuestion(id, '', id, [], false, 0, 10, 1, 5);
    case QuestionType.StarRating:
      return CreateStarRatingQuestion(id, '', id, [], false);
    case QuestionType.MatrixSingleSelect:
      return CreateMatrixSingleSelectQuestion(id, '', id, [], [], false, false);
    case QuestionType.MatrixMultiSelect:
      return CreateMatrixMultiSelectQuestion(id, '', id, [], [], false, false);
    case QuestionType.AutoSum:
      return CreateAutoSumQuestion(id, '', id, [], false, false, 0, 100);
    default:
      console.error(`Unable to instantiate question from type ${question}`);
      return null;
  }
}

export function getQuestionSubtitle(question: QuestionType): string | null {
  switch (question) {
    case QuestionType.SingleSelect:
      return 'PLEASE SELECT ONE';
    case QuestionType.MultiSelect:
      return 'PLEASE SELECT ALL THAT APPLY';
    case QuestionType.Ranking:
      return 'PLEASE RANK YOUR PREFERENCES';
    case QuestionType.NumericOpenEnd:
      return 'PLEASE PROVIDE A NUMBER';
    case QuestionType.DropDown:
      return 'PLEASE SELECT ONE';
    case QuestionType.OpenEnd:
      return 'PLEASE BE AS DESCRIPTIVE AS POSSIBLE';
    case QuestionType.Slider:
      return 'PLEASE SELECT AN OPTION';
    case QuestionType.StarRating:
      return 'PLEASE SELECT A STAR';
    case QuestionType.MatrixSingleSelect:
      return 'PLEASE SELECT ONE';
    case QuestionType.MatrixMultiSelect:
      return 'PLEASE SELECT ALL THAT APPLY';
    case QuestionType.AutoSum:
      return 'PLEASE PROVIDE VALUES FOR';
    default:
      return null;
  }
}

function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function sortOptions(in_array: Array<QuestionOption>, sort_responses: SortResponsesEnum) {
  const array = [...in_array];
  switch (sort_responses) {
    case SortResponsesEnum.None:
      return array;
    case SortResponsesEnum.Alphabetical:
      array.sort((a: QuestionOption, b: QuestionOption) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      return array;
    case SortResponsesEnum.Randomize:
      shuffleArray(array);
      return array;
  }
}
