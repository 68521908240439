import { call, put } from 'redux-saga/effects';
import { setStatus, setUser } from '../../features/user/userSlice';
import { apiGetSelf } from '../../services/userAPI';
import * as actions from '../actions';

/**
 * Get the current user information.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getUserSelf(action: ReturnType<typeof actions.getUserSelf>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const userResponse = yield call(apiGetSelf);
    // Set the user.
    yield put(setUser(userResponse.data));

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Now get the teams.
    yield put(actions.getTeams(userResponse.data));
  } catch (e) {
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
