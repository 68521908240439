import { Box, Grid, Input, Switch, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { SliderQuestion } from '../../../types/Question';
import PredefinedResponsesProperty from '../../leftproperties/PredefinedResponsesProperty';

import {
  setHasMultiple,
  setSliderDefaultValue,
  setSliderMaximum,
  setSliderMinimum,
  setSliderStep,
} from '../../surveydesigner/SurveyDesignerSlice';

export default function SliderProperties(props: { question: SliderQuestion }) {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <PredefinedResponsesProperty question={props.question} />

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Multiple answers</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            onChange={(event, value) => dispatch(setHasMultiple({ questionId: props.question.id, value }))}
            checked={props.question.has_multiple}
          ></Switch>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Minimum</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            type="number"
            value={props.question.minimum}
            onChange={(event) =>
              dispatch(setSliderMinimum({ questionId: props.question.id, value: parseInt(event.target.value) }))
            }
          ></Input>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Maximum</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            type="number"
            value={props.question.maximum}
            onChange={(event) =>
              dispatch(setSliderMaximum({ questionId: props.question.id, value: parseInt(event.target.value) }))
            }
          ></Input>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Step</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            type="number"
            value={props.question.step}
            onChange={(event) =>
              dispatch(setSliderStep({ questionId: props.question.id, value: parseInt(event.target.value) }))
            }
          ></Input>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Default Value</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            type="number"
            value={props.question.default_value}
            onChange={(event) =>
              dispatch(setSliderDefaultValue({ questionId: props.question.id, value: parseInt(event.target.value) }))
            }
          ></Input>
        </Grid>
      </Grid>
    </Box>
  );
}
