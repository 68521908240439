import Box from "@mui/material/Box";
// import { LeftDrawer } from "./drawers/left/LeftDrawer";
// import { useAppSelector, useAppDispatch } from "../../app/hooks";
// import { selectDocumentState, setError } from "./editorSlice";
import { useAppSelector } from "../../app/hooks";
// import XmlPreviewModal from "../xmlexport/XmlPreviewModal";
import SurveyDesigner from "./SurveyDesigner";

export default function SurveyDesignerContainer() {
  // const xmlExportState = useAppSelector(selectorXmlExport);

  return (
    <Box sx={{ width: "58vw", display: "inline-block", minHeight: "80vh" }}>

      <Box sx={{ flexGrow: 2, width: "100%" }}>
        <SurveyDesigner></SurveyDesigner>
      </Box>

    </Box>
  );
}
