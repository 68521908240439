import { takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { createSurvey, deleteSurvey, updateSurvey } from './sagas/surveys';
import { getTeams } from './sagas/teams';
import { getUserSelf } from './sagas/users';

export function* rootSaga() {
  // Users
  yield takeLatest(actions.getUserSelf.type, getUserSelf);

  // Teams
  yield takeLatest(actions.getTeams.type, getTeams);

  // Surveys
  yield takeLatest(actions.createSurvey.type, createSurvey);
  yield takeLatest(actions.updateSurvey.type, updateSurvey);
  yield takeLatest(actions.deleteSurvey.type, deleteSurvey);
}
