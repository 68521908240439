import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import logo from '../common/logo.png';

import { Question } from '../../types/Question';

import { Laptop, PhoneIphone, TabletMac } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { getQuestionPreviewControl, getQuestionSubtitle } from '../questions/common/utils';

/**
 * The question preview.
 *
 * @export
 * @return {*}
 */
export default function Preview(props: { selectedQuestion: Question | undefined }) {
  if (!props.selectedQuestion)
    return (
      <Box sx={{ margin: '20px' }}>
        <Typography>Please select a question.</Typography>
      </Box>
    );

  return (
    <Box sx={{}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <VisibilityIcon sx={{ mx: 1 }} />
        <Typography>Question preview</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 3,
          border: '1px solid #979797',
          backgroundColor: '#F2F2F2',
          borderRadius: 2,
          // TODO: Set after aligning on viewing options.
          height: '500px',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
            pb: 3,
            backgroundColor: '#FFFFFF',
            borderRadius: 2,
          }}
        >
          <img src={logo} height="60px" alt="tripintuLogo" />
          <Box>
            <Typography>
              <b>Q{props.selectedQuestion.id}</b> {props.selectedQuestion.title}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            zIndex: 1,
            height: 0,
            top: -15,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Button sx={{ borderRadius: 10, height: 30, color: 'white' }} variant="contained" color="primary">
            {getQuestionSubtitle(props.selectedQuestion.type)}
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, pt: 3, pb: 2, overflowY: 'scroll' }}>
          {getQuestionPreviewControl(props.selectedQuestion)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            p: 2,
            justifyContent: 'center',
          }}
        >
          <Button color="secondary" variant="contained" sx={{ width: '60%', borderRadius: 8 }}>
            <CheckIcon />
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton color="primary">
          <PhoneIphone></PhoneIphone>
        </IconButton>
        <IconButton disabled>
          <TabletMac></TabletMac>
        </IconButton>
        <IconButton disabled>
          <Laptop></Laptop>
        </IconButton>
      </Box>
    </Box>
  );
}
