import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { OpenEndQuestion } from "../../../types/Question";

import { useAppDispatch } from "../../../app/hooks";
import { setAnswerForLogic } from '../../surveydesigner/SurveyDesignerSlice';

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function OpenEndPreview(props: { question: OpenEndQuestion }) {
  const dispatch = useAppDispatch();



  let input;
  switch (props.question.response_length) {
    case "short":
      input = (
        <Input
          type="text"
          sx={{ padding: "10px", background: "#FFFFFF", width: "100%" }}
          value={props.question.answer_for_logic}
          onChange={(event) => {
            dispatch(setAnswerForLogic({
              questionId: props.question.id,
              value: event.target.value
            }))
          }}
        />
      );
      break;
    default:
      input = (
        <TextareaAutosize
          minRows={props.question.response_length == "medium" ? 3 : 5}
          style={{
            padding: "10px",
            background: "#FFFFFF",
            width: "100%",
          }}
          value={props.question.answer_for_logic}
          onChange={(event) => {
            dispatch(setAnswerForLogic({
              questionId: props.question.id,
              value: event.target.value
            }))
          }}
        ></TextareaAutosize>
      );
  }

  return (
    <Box>
      <Box sx={{ p: 1 }}>{input}</Box>
    </Box>
  );
}
