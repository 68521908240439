import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import CallbackPage from './features/auth/CallbackPage';
import { LogoutPage } from './features/auth/LogoutPage';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="callback" element={<CallbackPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
