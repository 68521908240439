import React from 'react';

import { Backdrop, DialogActions, DialogContent, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { getLoginUrl, logOut } from '../../services/TokenStorage';
import logo from '../common/logo_title.png';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function LogoutPage() {
  //Log us out.
  logOut();

  function login() {
    if (typeof window !== `undefined`) {
      // if(location && location.state && location.state.prevPath) {
      //     window.localStorage.setItem('prevPath', location.state.prevPath);
      // }
      window.location.replace(getLoginUrl());
    }
  }
  return (
    <Backdrop sx={{ color: '#fff' }} open={true}>
      <Dialog open={true} TransitionComponent={Transition}>
        <DialogTitle>
          <img src={logo} height="100px" style={{ marginLeft: '50px', marginRight: '50px' }} alt="tripintuogo" />
        </DialogTitle>
        <DialogContent>You're all logged out.</DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
          <Button variant="contained" color="primary" size="large" onClick={login}>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
}
