import { QuestionAnswer } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { NumericOpenEndQuestion } from "../../../types/Question";
import OneValueEditor from "../common/OneValueEditor";
import OptionsEditor from "../common/OptionsEditor";

export default function NumericOpenEndEditor(props: {
  question: NumericOpenEndQuestion;
}) {
  if (props.question.has_multiple) {
    return <Box>
      <Typography><i>Topics</i></Typography>
      <OptionsEditor question={props.question} />
      </Box>;
  }
  else {
    return <Box/>; //No editing options.
  }
}
