import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SurveyQuestion from './SurveyQuestion';
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Question } from '../../types/Question';

import { Survey } from '../../types/Surveys';
import SurveyDesignerFooter from './SurveyDesignerFooter';
import {
  selectorQuestions,
  selectorSelectedQuestion,
  selectorSurvey,
  setSelectedQuestionId,
  switchQuestions,
} from './SurveyDesignerSlice';

/**
 * The main survey editor.
 *
 * @export
 * @return {*}
 */
export default function SurveyDesigner() {
  const dispatch = useAppDispatch();
  const survey: Survey | undefined = useAppSelector(selectorSurvey);
  const questions: Array<Question> = useAppSelector(selectorQuestions);
  const currentSelectedQuestion: Question | undefined = useAppSelector(selectorSelectedQuestion);

  /**
   * Sort questions when they are dragged around.
   *
   * @param {*} result
   * @return {*}
   */

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
    dispatch(
      switchQuestions({
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      }),
    );
  }

  return (
    <Box sx={{ bgcolor: '#F2F2F2', overflowY: 'hidden', height: '80vh' }}>
      {/* Big page view */}
      <Box
        sx={{
          bgcolor: grey[100],
          px: 5,
          display: 'flex',
          flexDirection: 'column',
          height: '80vh',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        {/* Centered box for page */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="question_droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {questions.map((item: Question, index: number) => (
                    <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                      {(provided, snapshot) => (
                        <SurveyQuestion
                          key={item.id}
                          question={item}
                          selected={item == currentSelectedQuestion}
                          onClick={() => {
                            dispatch(setSelectedQuestionId({ valueId: item.id }));
                          }}
                          // ref={provided.innerRef}
                          // {...provided.draggableProps}
                          dragProvided={provided}
                          // dragHandleProps={provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        {/* Add question footer */}
        <SurveyDesignerFooter survey={survey!} questions={questions} />
      </Box>
    </Box>
  );
}
