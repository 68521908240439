import { combineReducers } from 'redux';
import appNavigationReducer from '../appNavigationSlice';
import surveyDesignerReducer from '../features/surveydesigner/SurveyDesignerSlice';
import teamsReducer from '../features/user/teamsSlice';
import userReducer from '../features/user/userSlice';

const rootReducer = combineReducers({
  user: userReducer,
  teams: teamsReducer,
  surveyDesigner: surveyDesignerReducer,
  appNavigation: appNavigationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
