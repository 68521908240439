import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { getPredefinedResponses } from '../../../types/PredefinedResponses';

import { QuestionOption, SingleSelectQuestion, SortResponsesEnum } from '../../../types/Question';
import { sortOptions } from '../common/utils';

type SingleSelectPreviewState = {
  options: QuestionOption[];
  sorting: SortResponsesEnum;
  predefined_response_id: number | null;
  preview_options: QuestionOption[];
};

function getPreviewStateFromQuestion(question: SingleSelectQuestion): SingleSelectPreviewState {
  const originalOptions = question.is_predefined_responses
    ? getPredefinedResponses(question.type, question.predefined_response_id)?.options ?? []
    : question.options;
  return {
    options: originalOptions,
    sorting: question.sort_responses,
    predefined_response_id: question.predefined_response_id,
    preview_options: sortOptions(originalOptions, question.sort_responses),
  };
}

function isEqual(a: SingleSelectPreviewState, b: SingleSelectPreviewState): boolean {
  return (
    a.options.length == b.options.length &&
    a.sorting == b.sorting &&
    a.predefined_response_id == b.predefined_response_id &&
    a.options.every((aOpt) => aOpt.name == b.options.find((_) => _.id == aOpt.id)?.name)
  );
}

/**
 * The preview of the Single Select question type.
 *
 * @export
 * @return {*}
 */
export default function SingleSelectPreview(props: { question: SingleSelectQuestion }) {
  // Our state of the options.
  const [previewState, setPreviewState] = useState<SingleSelectPreviewState>(
    getPreviewStateFromQuestion(props.question),
  );
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  //If anything changes, reset the options
  const newState = getPreviewStateFromQuestion(props.question);
  if (!isEqual(previewState, newState)) {
    setPreviewState(newState);
  }

  return (
    <Box sx={{ m: 1 }}>
      <FormControl component="fieldset" variant="standard" sx={{ p: 1, width: '100%' }}>
        <RadioGroup onChange={(e) => setSelectedOption(e.target.value)}>
          {previewState.preview_options.map((_, i) => (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              key={_.id}
              control={<Radio value={_.id} />}
              label={_.name}
            />
          ))}
          {props.question.add_noneoftheabove && (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              control={<Radio name="none_of_the_above" value="none" />}
              label="None of the above"
            />
          )}
          {props.question.add_other && (
            <FormControlLabel
              sx={{ backgroundColor: '#FFFFFF', m: 1 }}
              control={<Radio name="other" value="other" />}
              label="Other"
            />
          )}
          {selectedOption == 'other' ? (
            <Box sx={{ my: 1 }}>
              <TextField sx={{ backgroundColor: '#FFFFFF' }} fullWidth placeholder="Please specify"></TextField>
            </Box>
          ) : null}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
