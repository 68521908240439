import { Search } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';

export default function SearchBox() {
  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid item xs={10}>
        <TextField placeholder="Search" fullWidth></TextField>
      </Grid>
      <Grid item xs={2}>
        <IconButton>
          <Search fontSize="large"></Search>
        </IconButton>
      </Grid>
    </Grid>
  );
}
