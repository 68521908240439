import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { RootState } from '../../app/store';
import { Team, TeamMembership } from '../../types/Teams';

interface TeamState {
  teamMemberships: TeamMembership[];
  teams: Team[];
  selectedTeamId: number | undefined;
  status: 'idle' | 'loading' | 'error';
  error: string | undefined;
}

const initialState: TeamState = {
  teamMemberships: [] as TeamMembership[],
  teams: [] as Team[],
  selectedTeamId: undefined,
  status: 'idle',
  error: undefined,
};

export const teamsSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: undefined | string }>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setMemberships: (state, action: PayloadAction<TeamMembership[]>) => {
      state.teamMemberships = action.payload;
    },
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload;
      if (state.selectedTeamId === undefined && action.payload.length > 0) {
        state.selectedTeamId = state.teams[0].id;
      }
    },
    setSelectedTeamId: (state, action: PayloadAction<number>) => {
      const selected = state.teams.find((t) => t.id == action.payload);
      state.selectedTeamId = selected?.id;
    },
  },
});

export const { setStatus, setMemberships, setTeams, setSelectedTeamId } = teamsSlice.actions;

//Selectors
export const selectTeams = (state: RootState) => state.teams;
export const selectSelectedTeam = (state: RootState) =>
  state.teams.teams.find((t) => t.id == state.teams.selectedTeamId);

export default teamsSlice.reducer;
