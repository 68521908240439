import { Box, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { RankingQuestion } from '../../../types/Question';
import SortResponsesProperty from '../../leftproperties/SortResponsesProperty';
import { updateQuestion } from '../../surveydesigner/SurveyDesignerSlice';

export default function RankingProperties(props: { question: RankingQuestion }) {
  const dispatch = useAppDispatch();

  const [text, setText] = useState<string>(props.question.ranking_count.toString());

  function isValidNumber(str: string) {
    const n = parseInt(str);
    return n > 0 && n <= props.question.options.length;
  }

  return (
    <Box>
      <SortResponsesProperty question={props.question} />

      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          borderTop: 1,
          borderColor: '#F2F2F2',
          p: 1,
          m: 1,
        }}
      >
        <Grid item sm={6}>
          <Typography>Ranking choices</Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            type="number"
            value={text}
            error={!isValidNumber(text)}
            //Don't control the actual text
            onChange={(e) => {
              setText(e.target.value);
              if (isValidNumber(e.target.value)) {
                // Update the question.
                const updatedQ = { ...props.question, ranking_count: parseInt(e.target.value) };
                dispatch(updateQuestion(updatedQ));
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
